import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import DataTable from "../widget/table/Datatable";
import { useLocation } from "react-router-dom";
import { getDataByExcelFileId } from "../service/excelUpload";

export default function Products() {
  const { state } = useLocation();

  const excelFileId = state.fileId;
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "Product Name",
      selector: (row) => row.productName,
      sortable: true,
    },

    {
      name: " Description",
      selector: (row) => row.productDescription,
      sortable: true,
    },
    {
      name: " Amount",
      selector: (row) => row.amount,
      cell: (row) => <span className="">${row.amount}</span>,
      sortable: true,
    },
  ];

  // const data = [
  //   {
  //     id: 1,
  //     productName: "Laptop",

  //     amount: "$76856.81",
  //   },
  //   {
  //     id: 2,
  //     productName: "Laptop",

  //     amount: "$76856.81",
  //   },
  //   {
  //     id: 3,
  //     productName: "Laptop",

  //     amount: "$76856.81",
  //   },
  //   {
  //     id: 4,
  //     productName: "Laptop",

  //     amount: "$76856.81",
  //   },
  //   {
  //     id: 5,
  //     productName: "Laptop",

  //     amount: "$76856.81",
  //   },
  //   {
  //     id: 6,
  //     productName: "Laptop",

  //     amount: "$76856.81",
  //   },
  // ];

  useEffect(() => {
    getDataByExcelFileIdForProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataByExcelFileIdForProduct = async () => {
    const result = await getDataByExcelFileId(excelFileId);
    console.log(result.data, "dtata");
    setData(result?.data);
  };
  return (
    <Container fluid className="p-0 my-3 product_list">
      <div className="">
        <div className="main_title"> Product List</div>
        <Breadcrumb>
          <Breadcrumb.Item href="/UploadExcel"> Excel List</Breadcrumb.Item>
          <Breadcrumb.Item href="/Products" className="CurrentClass">
            {" "}
            Products List
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>{" "}
      <Row>
        <Col md={12}>
          <div className="border-0 p-3 rounded card">
            <div className="title-container">
              <p>Product List</p>
            </div>
            <DataTable columns={columns} data={data} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
