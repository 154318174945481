import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import doller from "../assets/image/doller.png";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import Datatable from "../widget/table/Datatable";
import GaugeChart from "../widget/chart/GaugeChart";
import DonutChart from "../widget/chart/DonutChart";
import { BarChart } from "@mui/x-charts/BarChart";
import RadialChart from "../widget/chart/RadialChart";
import {
  countAwardedJobs,
  countLostJobs,
  countPendingJobs,
  GetBreakEvenCost,
  gettopTenJobs,
} from "../service/jobs";
import { GetjobConfiguration } from "../service/jobconfigApi";
import { GetTotalAdditionalRevenue } from "../service/additionalRevenueApi";
import JobCost from "./JobCost";
import moment from "moment/moment";
import { Link } from "react-router-dom";

export default function AdminDashboard() {
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const companyName = userInfo?.companyName;
  const [awardedCount, setAwardedCount] = useState(0);
  const [awardedPercentage, setAwardedPercentage] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [pendingPercentage, setPendingPercentage] = useState(0);
  const [lostCount, setLostCount] = useState(0);
  const [lostPercentage, setLostPercentage] = useState(0);
  const [jobConfigs, setJobConfigs] = useState(null);
  const [laborTotal, setLaborTotal] = useState(0);
  const [overheadTotal, setOverheadTotal] = useState(0);
  // const [totalBreakevenMonth, setTotalBreakevenMonth] = useState(0);
  const [totalBreakdownMonth, setTotalBreakdownMonth] = useState(0);
  // const [totalBreakevenYear, setTotalBreakevenYear] = useState(0);
  const [totalBreakdownYear, setTotalBreakdownYear] = useState(0);
  const [selectedMonthlyConfig, setSelectedMonthlyConfig] = useState(null);
  const [selectedYearlyConfig, setSelectedYearlyConfig] = useState(null);
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [isMonthly, setIsMonthly] = useState(true);
  const [data, setData] = useState([]);
  const [selectedJobConfigNameMonth, setSelectedJobConfigNameMonth] =
    useState("");
  const [selectedJobConfigNameYear, setSelectedJobConfigNameYear] =
    useState("");

  useEffect(() => {
    fetchTopJobs();
  }, []);

  const fetchTopJobs = async () => {
    const result = await gettopTenJobs();
    setData(result?.data);
  };

  const fetchMonthlyData = async () => {
    const result = await GetTotalAdditionalRevenue("Monthly");
    setMonthlyData(result?.data);
  };
  const [barChartData, setBarChartData] = useState({
    estimate: [],
    actual: [],
    aAxis: [],
  });

  const fetchYearlyData = async () => {
    const result = await GetTotalAdditionalRevenue("yearly");
    setYearlyData(result?.data);
  };

  useEffect(() => {
    fetchMonthlyData();
    fetchYearlyData();
  }, []);
  useEffect(() => {
    if (isMonthly) {
      setBarChartData({
        estimate: monthlyData?.map((item) => item?.estimatedRevenue) ?? [],
        actual:
          monthlyData?.map(
            (item) => item.actualRevenue
            // (item) => (item.actualRevenue != 1011947843 ? item.actualRevenue : 0)
          ) ?? [],
        aAxis:
          monthlyData?.map((item) => item?.names.trim().substring(0, 3)) ?? [],
      });
    } else {
      setBarChartData({
        estimate: yearlyData?.map((item) => item?.estimatedRevenue) ?? [],
        actual: yearlyData?.map((item) => item?.actualRevenue) ?? [],
        aAxis: yearlyData?.map((item) => item?.names.trim()) ?? [],
      });
    }
  }, [yearlyData, monthlyData, isMonthly]);

  useEffect(() => {
    if (jobConfigs && jobConfigs.length > 0) {
      setSelectedMonthlyConfig(jobConfigs[0]);
      setSelectedYearlyConfig(jobConfigs[0]);
      handleClickMonth(jobConfigs[0]);
      handleClickYear(jobConfigs[0]);
    }
  }, [jobConfigs]);
  useEffect(() => {
    GetPending();
    GetAwarded();
    GetLost();
  }, []);
  const GetAwarded = async () => {
    const result = await countAwardedJobs();
    setAwardedCount(result?.data?.count || 0);
    setAwardedPercentage(result?.data?.percentage || 0);
  };
  const GetPending = async () => {
    const result = await countPendingJobs();
    setPendingCount(result?.data?.count || 0);
    setPendingPercentage(result?.data?.percentage || 0);
  };
  const GetLost = async () => {
    const result = await countLostJobs();
    setLostCount(result?.data?.count || 0);
    setLostPercentage(result?.data?.percentage || 0);
  };

  const columns = [
    {
      name: "User Name",
      selector: (row) => row.customerName,
      sortable: true,
    },
    {
      name: "Job Name",
      selector: (row) => row.jobName,
      sortable: true,
    },
    {
      name: "Job Configuration",
      selector: (row) => row.jobsConfiguration,
      sortable: true,
    },

    {
      name: "Breakeven Cost",
      selector: (row) => `$${row.breakEvenJobCost.toLocaleString()}`,
      sortable: true,
    },
    // {
    //   name: "Commission",
    //   selector: (row) => `$${row.commissionAmount.toFixed(2).toLocaleString()}`,
    //   sortable: true,
    // },

    // {
    //   name: "Finance Fee",
    //   selector: (row) => `$${row.financeFeeAmount.toFixed(2).toLocaleString()}`,
    //   sortable: true,
    // },
    {
      name: "Bid Cost",
      selector: (row) => `$${row.bidCost.toLocaleString()}`,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdDate).format("MM/DD/YYYY"),
      // selector: (row) => moment(row.createdDate).format("MM/DD/YYYY h:mm A"),
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <Button
          variant={
            row.status === "Pending"
              ? "outline-warning"
              : row.status === "Awarded"
              ? "outline-success"
              : "outline-danger"
          }
        >
          {row.status}
        </Button>
      ),
    },
  ];

  // Next and Prev slider for chart
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const getConfiguration = async () => {
    const response = await GetjobConfiguration();
    setJobConfigs(response?.data);
    setSelectedJobConfigNameMonth(response?.data[0].jobConfigName);
    setSelectedJobConfigNameYear(response?.data[0].jobConfigName);
  };
  useEffect(() => {
    getConfiguration();
  }, []);

  const handleClickMonth = async (job) => {
    const data = await GetBreakEvenCost(job.id);

    if (data) {
      setLaborTotal(data?.data?.laborTotal);
      setOverheadTotal(data?.data?.overheadTotal);
 
      setTotalBreakdownMonth(data?.data?.breakevenWithVariablePerMonth);
      setSelectedMonthlyConfig(job);

      setSelectedJobConfigNameMonth(job?.jobConfigName);
    }
  };
  const handleClickYear = async (job) => {
    const data = await GetBreakEvenCost(job.id);

    if (data) {
      setLaborTotal(data?.data?.laborTotal);
      setOverheadTotal(data?.data?.overheadTotal);
    
      setTotalBreakdownYear(data?.data?.breakevenWithVariablePerYear);
      setSelectedYearlyConfig(job);

      setSelectedJobConfigNameYear(job?.jobConfigName);
    }
  };

  return (
    <Container fluid className="p-0 my-3  dashboardSection">
      <Row className="cardSection ">
        <Col sm={4} className="mb-4 main_header">
          <div className="bg-white card1">
            <div className="cardGroup m-4">
              <div className="header">Welcome to {companyName} </div>
              <p>Looking forward to a great day with you.</p>
            </div>
          </div>
        </Col>

        <Col sm className="mb-4 jobs_status">
          <Link
            to="/JobList"
            state={{ status: "Awarded" }}
            className="text-decoration-none box_cont"
          >
            <div className="bg-white card2">
              {" "}
              <div className="stats p-2">
                <div className="leftSect">
                  <div className="mb-3">
                    <span className="percent  d-flex align-items-center">
                      {" "}
                      <FontAwesomeIcon icon={faArrowUp} /> +
                      {awardedPercentage.toFixed(0)}%
                    </span>
                  </div>
                  <p className="mb-1">Awarded Jobs</p>
                </div>
                <div className="rightSect">
                  <div className="gChartSect">
                    <GaugeChart
                      value={awardedCount}
                      fillColor="#30DA8E"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </Col>
        <Col sm className="mb-4 jobs_status">
          <Link
            to="/JobList"
            state={{ status: "Pending" }}
            className="text-decoration-none box_cont"
          >
            <div className="bg-white card3">
              {" "}
              <div className="stats p-2">
                <div className="leftSect">
                  <div className="mb-3">
                    <span className="percent d-flex align-items-center ">
                      <FontAwesomeIcon icon={faArrowDown} /> +
                      {pendingPercentage.toFixed(0)}%
                    </span>
                  </div>
                  <p className="mb-1">Pending Jobs</p>
                </div>
                <div className="rightSect">
                  <div className="gChartSect">
                    <GaugeChart
                      value={pendingCount}
                      fillColor="#4F46E5"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </Col>
        <Col sm className="mb-4 jobs_status">
          <Link
            to="/JobList"
            state={{ status: "Lost" }}
            className="text-decoration-none box_cont"
          >
            <div className="bg-white card4">
              {" "}
              <div className="stats p-2">
                <div className="leftSect">
                  <div className="mb-3">
                    <span className="percent d-flex align-items-center">
                      <FontAwesomeIcon icon={faArrowDown} /> -{" "}
                      {lostPercentage.toFixed(0)}%
                    </span>
                  </div>
                  <p className="mb-1"> Lost Jobs</p>
                </div>
                <div className="rightSect">
                  <div className="gChartSect">
                    <GaugeChart
                      value={lostCount}
                      fillColor="#FFD498"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row className="chart-container ">
        <Col sm={3} className="mb-4 ">
          <JobCost />
        </Col>
        <Col sm={6} className="mb-4 total_revnue_chart">
          <div className="bg-white">
            {" "}
            <div className="d-flex">
              {" "}
              <h6>Total Revenue</h6>
              <Form.Select
                aria-label="Select Revenue Period"
                className="form-control ms-auto me-2"
                onChange={(e) => {
                  setIsMonthly(e.target.value === "Monthly");
                  if (e.target.value === "Monthly") {
                    fetchMonthlyData();
                  } else {
                    fetchYearlyData();
                  }
                }}
              >
                <option value="Monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </Form.Select>
            </div>
            <BarChart
              series={[
                {
                  data: barChartData?.actual,
                  color: "#3464D0",
                  label: "Actual",
                },
              ]}
              height={290}
              borderRadius={10}
              xAxis={[
                {
                  data: barChartData.aAxis,
                  scaleType: "band",
                  style: {
                    fontSize: 12,
                    fontWeight: "300",
                  },
                },
              ]}
              yAxis={[
                {
                  title: {
                    text: "Revenue ($)",
                  },

                  tickFormat: (value) => `$${value.toLocaleString()}`,
                },
              ]}
              grid={{ horizontal: true }}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />
            <div className="label_container">
              <div className="d-flex align-items-center">
                <div className="actual"></div>
                <span>Actual</span>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={3} className="mb-4 ">
          <div className="bg-white">
            <div className="custom-slider">
              <div className="slider-container customSliderChart">
                <Slider
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                  {...settings}
                >
                  <div key={1}>
                    {" "}
                    <div className="nextSlider position-relative">
                      <div className=" d-flex">
                        <Dropdown className="d-inline custom_dropdown ">
                          <Dropdown.Toggle id="dropdown-autoclose-true">
                            <FontAwesomeIcon icon={faGear} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {jobConfigs?.map((job) => (
                              <Dropdown.Item
                                href="#"
                                key={job.id}
                                onClick={() => handleClickMonth(job)}
                                style={{
                                  color:
                                    selectedMonthlyConfig?.id === job.id
                                      ? "green"
                                      : "black",
                                }}
                              >
                                <span>{job?.jobConfigName}</span>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <button className=" custom_next_btn " onClick={next}>
                          Monthly Breakeven Cost{" "}
                          <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                      </div>
                      <div className="nextChart  pb-3">
                        <div className="d-flex justify-content-center align-items-center custom_title">
                          {selectedJobConfigNameMonth && (
                            <h6 className="">{selectedJobConfigNameMonth}</h6>
                          )}
                        </div>
                        <div className="customChart">
                          <DonutChart
                            series={[laborTotal, overheadTotal]}
                            labels={["Labor Total", "Overhead Total"]}
                            colors={["#F99963", "#314CFF"]}
                            height={300}
                          />

                    
                        </div>
                        <div className="SecondChartsliderText ">
                          <div
                            id="chart"
                            className="secondChart position-relative"
                          >
                            <RadialChart value={totalBreakdownMonth} />
                            <div className="position-absolute ">
                              <img src={doller} alt="" />
                            </div>
                          </div>
                          <div className="secondText">
                            {" "}
                            <p>
                              <strong>
                                $
                                {parseFloat(
                                  totalBreakdownMonth
                                ).toLocaleString()}{" "}
                                /month
                              </strong>
                            </p>
                            <p className="info_amount">
                              Total Breakeven with Variable Cost
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div key={2}>
                    {" "}
                    <div className="prevSlider position-relative">
                      <div className="d-flex">
                        <button className=" custom_prev_btn" onClick={previous}>
                          <FontAwesomeIcon icon={faAngleLeft} />
                          Yearly Breakeven Cost
                        </button>
                        <Dropdown className="d-inline custom_dropdown mx-1">
                          <Dropdown.Toggle id="dropdown-autoclose-true">
                            <FontAwesomeIcon icon={faGear} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {jobConfigs?.map((job) => (
                              <Dropdown.Item
                                href="#"
                                key={job.id}
                                onClick={() => handleClickYear(job)}
                                style={{
                                  color:
                                    selectedYearlyConfig?.id === job.id
                                      ? "green"
                                      : "black",
                                }}
                              >
                                <span>{job.jobConfigName}</span>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="prevChart pb-3">
                        {" "}
                        <div className="d-flex justify-content-center align-items-center custom_title">
                          {selectedJobConfigNameYear && (
                            <h6 className="">{selectedJobConfigNameYear}</h6>
                          )}
                        </div>
                        <div className="customChart">
                          <DonutChart
                            series={[laborTotal, overheadTotal]}
                            labels={["Labor Total", "Overhead Total"]}
                            colors={["#F99963", "#314CFF"]}
                            height={300}
                          />
               
                        </div>
                        <div className="SecondChartsliderText ">
                          <div
                            id="chart"
                            className="secondChart position-relative"
                          >
                            <RadialChart value={totalBreakdownYear} />
                            <div className="position-absolute ">
                              <img src={doller} alt="" />
                            </div>
                          </div>
                          <div className="secondText">
                            {" "}
                            <p>
                              <strong>
                                $
                                {parseFloat(
                                  totalBreakdownYear
                                ).toLocaleString()}{" "}
                                /Year
                              </strong>
                            </p>
                            <p className="info_amount">
                              Total Breakeven with Variable Cost
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mb-1 ">
          <div className="bg-white p-3 topTenJobsSection">
            <div className="title-container">
              <p>Top 10 Jobs</p>
            </div>
            <Datatable columns={columns} data={data} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
