// =============================================================Job Configuration=========================================================

import axiosInstance from "../axiosInstance";

// JobConfiguration post API
export const jobConfiguration = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/JobConfiguration/CreateJobConfig",
      formdata
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

// JobConfiguration get API
export const GetjobConfiguration = async () => {
  try {
    const response = await axiosInstance.get(
      "/JobConfiguration/GetAllJobConfiguration"
    );
    return response.data;
  } catch (error) {
    console.log(
      error,
      "--------------------------job configuration Get All Data"
    );
  }
};

// JobConfiguration for Job config By ID API
export const GetjobConfigByID = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/JobConfiguration/GetJobConfigurationById?id=${id}`
    );
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------job configuration by id ");
  }
};

// JobConfiguration for Edit API
export const updateJobConfig = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/JobConfiguration/UpdateJobConfig",
      formdata
    );
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------job configuration by id ");
  }
};

// JobConfiguration for Delete API
export const DeleteJobConfig = async (id) => {
  try {
    const response = await axiosInstance.post(
      `/JobConfiguration/DeleteJobConfig?id=${id}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting job configuration:",
      error.response ? error.response.data : error.message
    );
  }
};
export const GetJobconfigurationByComapnyID = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/JobConfiguration/GetJobConfigByAdminId?AdminId=${id}`
    );
    return response.data;
  } catch (error) {
    console.log(
      error,
      "--------------------------job configuration Get All Data"
    );
  }
};
