import React from "react";
import { isAuthenticated } from "../helpers";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const PrivateRoute = () => {
  const navigate = useNavigate();
  const isAuth = isAuthenticated();

  React.useEffect(() => {
    if (!isAuth) {
      navigate("/");
    } else {
      // navigate("/Dashboard");
    }

  }, [isAuth, navigate]);

  return isAuth ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
