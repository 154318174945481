import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";

import DonutChart from "../widget/chart/DonutChart";
import { GetjobConfiguration } from "../service/jobconfigApi";
import { getJobCost } from "../service/jobs";

export default function JobCost() {
  const [jobConfigs, setJobConfigs] = useState([]);
  const [selectedJobConfig, setSelectedJobConfig] = useState(null);
  const [timePeriod, setTimePeriod] = useState("Monthly");
  const [selectedJobConfigName, setSelectedJobConfigName] = useState("");
  const [chartData, setChartData] = useState({
    series: [],
    labels: [],
    colors: ["#E7E064", "#0D9949"],
  });

  const getConfiguration = async () => {
    const response = await GetjobConfiguration();
    setJobConfigs(response?.data);
    if (response?.data.length > 0) {
      setSelectedJobConfig(response?.data[0].id);
      setSelectedJobConfigName(response?.data[0].jobConfigName);
    }
  };

  const fetchJobCostData = async () => {
    if (!selectedJobConfig) return;

    const message = timePeriod === "Monthly" ? "monthly" : "yearly";
    try {
      const response = await getJobCost(selectedJobConfig, message);

      const bidAmount = response?.data?.bidAmount;
      const jobSalePrice = response?.data?.jobSalePrice;

      if (response?.status) {
        setChartData({
          series: [jobSalePrice, bidAmount],
          labels: [
            `Total Amount Sold $${jobSalePrice || 0}`,
            `Total Amount Estimated $${bidAmount || 0}`,
          ],
          colors: ["#E7E064", "#0D9949"],
        });
      } else {
        const jobSalePrice = response.response.data.data.jobSalePrice;
        const bidAmount = response.response.data.data.bidAmount;
        setChartData({
          series: [jobSalePrice, bidAmount],
          labels: [
            `Total Amount Sold $${jobSalePrice}`,
            `Total Amount Estimated $${bidAmount}`,
          ],
          colors: ["#E7E064", "#0D9949"],
        });
   
      }
    } catch (error) {
      console.error("Error fetching job cost data:", error);
    }
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  useEffect(() => {
    fetchJobCostData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobConfig, timePeriod]);
  const handleJobConfigSelect = (job) => {
    setSelectedJobConfig(job.id);
    setSelectedJobConfigName(job.jobConfigName);
  };
  return (
    <div className="bg-white custom-section position-relative job_cost">
      <div className="jobCost">
        <h6>Job Details</h6>

        <Form.Select
          aria-label="Default select example"
          className="form-control ms-auto me-2"
          onChange={(e) => setTimePeriod(e.target.value)}
        >
          <option value="Monthly">Monthly</option>
          <option value="Yearly">Yearly</option>
        </Form.Select>

        <Dropdown className="d-inline custom_dropdown mx-1">
          <Dropdown.Toggle id="dropdown-autoclose-true">
            <FontAwesomeIcon icon={faGear} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {jobConfigs?.map((job) => (
              <Dropdown.Item
                href="#"
                key={job.id}
          
                onClick={() => handleJobConfigSelect(job)}
              >
                <span
                  style={{
                    color: selectedJobConfig === job.id ? "#0D9949" : "#000",
                  }}
                >
                  {job.jobConfigName}
                </span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex justify-content-center align-items-center custom_title">
        {selectedJobConfigName && <h6 className="">{selectedJobConfigName}</h6>}
      </div>
      <DonutChart
        series={chartData.series}
        labels={chartData.labels}
        colors={chartData.colors}
        height={350}
      />
    </div>
  );
}
