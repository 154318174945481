import React from "react";
// import { Carousel } from "react-bootstrap";
import sliderBg from "../../assets/image/sliderBg.png";

export default function SampleSlider() {
  // useEffect(() => {
  //   let anchor1 = document.getElementsByClassName("carousel-control-next");
  //   let anchor2 = document.getElementsByClassName("carousel-control-prev");

  //   let parentNode = document.createElement("div");
  //   parentNode.classList.add("slider_Action");
  //   if (anchor1.length > 0 && anchor2.length > 0) {
  //     parentNode.appendChild(anchor1[0]);
  //     parentNode.appendChild(anchor2[0]);
  //     document.body.appendChild(parentNode);
  //   } else {
  //     console.error("One or more elements were not found");
  //   }
  // }, []);
  return (
    <div className="slider-left-side p-5">
      <div className="slider-text">
        <h1 className="slider-headding text-white">Introducing new features</h1>
        <p className="slider-para text-white">
          Try our full product out for free on a 7-day trial. Get the pricing
          knowledge your company deserves. Throw away your old price books look
          up live parts and price with your true overhead. Also get real monthly
          and yearly goals that you can track to keep your team pushing to the
          next level.
        </p>
      </div>
      <div className="carousel-slider">
        <img className="d-block w-100" src={sliderBg} alt="First slide" />
        {/* <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img className="d-block w-100" src={sliderBg} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={sliderBg} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={sliderBg} alt="Third slide" />
          </Carousel.Item>
        </Carousel> */}
      </div>
    </div>
  );
}
