import {
  faEye,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Datatable from "../widget/table/Datatable";
import { Link } from "react-router-dom";
import {
  createCompanyAdmin,
  deleteCompanyAdmin,
  getAllCompanyAdmin,
  getCompanyAdminById,
  updateCompanyAdmin,
} from "../service/companyManagement";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import eye from "../assets/image/eye.png";
import eye_of from "../assets/image/eye_off.png";
import { updateStatusofUser } from "../service/userManagement";
import ReactLoading from "react-loading";
import moment from "moment";

export default function SuperDashboard() {
  const initialValues = {
    id: null,
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    mobile: "",
    password: "",
    subscriptionPayment: "",
    userLimit: "",
    subscriptionDuration: moment(),
    address1: "",
    address2: "",
    province: "",
    city: "",
    postalCode: "",
    country: "",
  };
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [Modalshow, setModalshow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setModalshow(false);
    setFormValues(initialValues);
    // AllCompanyAdmin();
    setErrors({});
  };
  const handleShow = () => {
    setModalshow(true);
  };
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const {
    firstName,
    lastName,
    companyName,
    email,
    mobile,
    password,
    subscriptionPayment,
    userLimit,
    subscriptionDuration,
    address1,
    address2,
    province,
    city,
    postalCode,
    country,
  } = formValues;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };
  const handleDateChange = (date1) => {
    const date = new Date(date1);
    const pad = (num) => num.toString().padStart(2, "0");
    const formattedDate = `${date?.getFullYear()}-${pad(
      date.getMonth() + 1
    )}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
      date.getMinutes()
    )}:${pad(date.getSeconds())}.${date.getMilliseconds()}`;

    setStartDate(formattedDate);

    console.log(formattedDate, "res-----------");
    setFormValues({
      ...formValues,
      subscriptionDuration: formattedDate,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!firstName) {
      newErrors.firstName = "Enter your First name";
    }
    if (!lastName) {
      newErrors.lastName = "Enter your Last name";
    }
    if (!companyName) {
      newErrors.companyName = "Enter Company name";
    }
    if (!email) {
      newErrors.email = "Enter your email";
    }
    if (!mobile) {
      newErrors.mobile = "Enter your phone number";
    }

    if (!formValues.id) {
      if (!password) {
        newErrors.password = "Enter your Password";
      } else if (password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
      } else if (!/\d/.test(password)) {
        newErrors.password = "Password must contain at least one digit";
      } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        newErrors.password =
          "Password must contain at least one special character";
      }
    }
    if (!subscriptionPayment) {
      newErrors.subscriptionPayment = "Enter your Duration";
    }

    if (!userLimit) {
      newErrors.userLimit = "Enter User Limit";
    }
    if (!subscriptionDuration) {
      newErrors.subscriptionDuration = "Enter Duration";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      setLoader(true);

      try {
        let response = null;
        const obj = {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          companyName: formValues.companyName,
          email: formValues.email,
          mobile: formValues.mobile,
          subscriptionPayment: formValues.subscriptionPayment,
          userLimit: formValues.userLimit,
          subscriptionDuration: formValues.subscriptionDuration,
        };
        if (formValues.password) {
          obj.password = formValues.password;
        }
        if (formValues.address1) obj.address1 = formValues.address1;
        if (formValues.address2) obj.address2 = formValues.address2;
        if (formValues.province) obj.province = formValues.province;
        if (formValues.city) obj.city = formValues.city;
        if (formValues.postalCode) obj.postalCode = formValues.postalCode;
        if (formValues.country) obj.country = formValues.country;

        if (formValues.id) {
          obj.id = formValues.id;

          response = await updateCompanyAdmin(obj);
          toast.success("Company Admin is updated successfully!", {
            autoClose: 3000,
          });
        } else {
          response = await createCompanyAdmin(obj);
          if (!response?.status) {
            toast.error(response?.response?.data?.message);
          } else {
            toast.success("Company Admin is added successfully!");
          }
        }

        if (response) {
          setData((prevData) => [response?.data, ...prevData]);
          await AllCompanyAdmin();
          setFormValues(initialValues);
          handleClose();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    }
  };

  const columns = [
    {
      name: "Company",
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: "User Occupied",
      selector: (row) => row.userOccupied,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Total Jobs",
      selector: (row) => row.jobCount,
      sortable: true,
    },

    {
      name: "Subscription Payment",
      selector: (row) => row.subscriptionPayment,
      sortable: true,
      cell: (row) => {
        const payment = row.subscriptionPayment;
        return `$${payment?.toLocaleString()}`;
      },
    },
    {
      name: "User Limit",
      selector: (row) => row.userLimit,
      sortable: true,
    },
    {
      name: "End Date",

      selector: (row) => row.subscriptionDuration?.replace("T", "  "),
      // selector: (row) =>
      //   moment(row.subscriptionDuration).utc().format("MM/DD/YYYY h:mm A"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>
        getStatusFromLocalStorage(row.id) ? "Approved" : "Approve",

      cell: (row) => (
        <Button
          variant={
            getStatusFromLocalStorage(row.id)
              ? "outline-success"
              : "outline-danger"
          }
          className="superDashboard"
          onClick={() => handleStatusChange(row)}
        >
          {getStatusFromLocalStorage(row.id) ? "Approved" : "Unapproved"}
        </Button>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="actionBtngroup">
          <Link
            to="/UserManagement"
            state={{ adminID: row?.id, companyName: row.companyName }}
            className="btn custom-btn px-2 py-1 w-auto"
          >
            <FontAwesomeIcon icon={faEye} className="me-1" />
            User
          </Link>
          <Button variant="" onClick={() => handleEdit(row.id)}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button variant="" onClick={() => handleDelete(row.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];
  const getStatusFromLocalStorage = (adminId) => {
    return localStorage.getItem(`admin-${adminId}-status`) === "true";
  };

  const handleStatusChange = async (row) => {
    let statuscode = !row.status;
    localStorage.setItem(`admin-${row.id}-status`, statuscode);

    const updatedStatus = await updateStatusofUser(row.id, statuscode);

    if (updatedStatus) {
      setData((prevData) =>
        prevData.map((admin) =>
          admin.id === row.id ? { ...admin, status: statuscode } : admin
        )
      );

      if (updatedStatus.data) {
        toast.success("Company admin approved successfully!", {
          autoClose: 3000,
        });
      } else {
        toast.error("Company admin unapproved successfully!", {
          autoClose: 3000,
        });
      }
    } else {
      toast.error("Failed to update Admin status.", { autoClose: 3000 });
    }
  };
  // Get Revenue

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const adminData = await getAllCompanyAdmin();
        adminData?.data.forEach((admin) => {
          localStorage.setItem(`admin-${admin.id}-status`, admin?.isActive);
        });
        await AllCompanyAdmin();
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserData();
  }, []);

  const AllCompanyAdmin = async () => {
    const result = await getAllCompanyAdmin();
    const formattedData = result?.data.map((admin) => ({
      ...admin,
      title: `${admin.companyName}`,
    }));
    // .sort(
    //   (a, b) =>
    //     new Date(b.subscriptionDuration) - new Date(a.subscriptionDuration)
    // );
    setData(formattedData);
  };

  // getCompanyAdminById
  const handleEdit = async (id) => {
    try {
      let data = await getCompanyAdminById(id);
      if (data?.status) {
        setFormValues({
          ...formValues,
          id: data.data.id ?? id,
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          companyName: data.data.companyName,
          email: data.data.email,
          mobile: data.data.mobile,

          password: data.data.password,
          subscriptionPayment: data.data.subscriptionPayment,
          userLimit: data.data.userlimit,
          subscriptionDuration: data.data.subscriptionDuration,
          address1: data.data.address1,
          address2: data.data.address2,
          province: data.data.province,
          city: data.data.city,
          postalCode: data.data.postalCode,
          country: data.data.country,
        });
        setModalshow(true);
      } else {
      }
    } catch (error) {
      console.error("Error occurred while editing:", error);
    }
  };
  // Delete Revenue
  const handleDelete = async (id) => {
    try {
      console.log("Delete clicked for ID:", id);
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed) {
        const response = await deleteCompanyAdmin(id);
        if (response) {
          await AllCompanyAdmin();
          Swal.fire("Deleted!", "Admin deleted successfully!", "success");
        } else {
          Swal.fire("Error!", "Failed to delete Company Admin.", "error");
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <Container fluid className="p-0 my-3 super_dashboard ">
      <div className="d-flex justify-content-between align-items-center ">
        {" "}
        <div>
          <div className="main_title"> Dashboard</div>
          <Breadcrumb className="superAdmin">
            <Breadcrumb.Item
              href="/Dashboard"
              className="CurrentClass"
            ></Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Button className="btn custom-btn mb-3 border-0" onClick={handleShow}>
            <FontAwesomeIcon icon={faPlus} /> Add Company
          </Button>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <div className="border-0 p-3 rounded card ">
            <div className="title-container">
              <p>Company List</p>
            </div>
            <Datatable columns={columns} data={data} />
          </div>
        </Col>
      </Row>

      <Modal show={Modalshow} onHide={handleClose} centered size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>
              {" "}
              {formValues.id ? "Update Company" : "Add Company"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="custom_form mb-4 position-relative">
              <h6>Company Info</h6>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label> First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.firstName ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Customer Name"
                    />
                    {errors.firstName && (
                      <Form.Text className="text-danger">
                        Enter your first name
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label> Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.lastName ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Customer Name"
                    />
                    {errors.lastName && (
                      <Form.Text className="text-danger">
                        Enter your last name
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label> Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.companyName ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Company Name"
                    />
                    {errors.companyName && (
                      <Form.Text className="text-danger">
                        Enter your Company name
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.email ? "border-danger mb-0" : ""
                      }`}
                      placeholder="xyz@info.com"
                    />
                    {errors.email && (
                      <Form.Text className="text-danger">
                        Enter your email
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobile"
                      value={mobile}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.mobile ? "border-danger mb-0" : ""
                      }`}
                      placeholder="+1 (751) 505-4112"
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.mobile && (
                      <Form.Text className="text-danger">
                        {errors.mobile === "Enter your number"
                          ? "Enter your number"
                          : "Phone number must be exactly 10 digits"}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      Password {formValues.id ? "(optional)" : ""}
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        className={`ps-3 pe-5 ${
                          errors.password ? "border-danger mb-0" : ""
                        }`}
                        placeholder="Enter a Password"
                      />

                      <img
                        src={showPassword ? eye : eye_of}
                        alt="Toggle Password Visibility"
                        className="position-absolute right_icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                    {errors.password && (
                      <Form.Text className="text-danger">
                        {errors.password}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Subscription Payment</Form.Label>
                    <Form.Control
                      type="number"
                      name="subscriptionPayment"
                      value={subscriptionPayment}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.subscriptionPayment ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Subscription Pay"
                    />
                    {errors.subscriptionPayment && (
                      <Form.Text className="text-danger">
                        Enter Duration
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Add User Limit</Form.Label>
                    <Form.Control
                      type="number"
                      name="userLimit"
                      value={userLimit}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.userLimit ? "border-danger mb-0" : ""
                      }`}
                      placeholder="User Limit"
                    />
                    {errors.userLimit && (
                      <Form.Text className="text-danger">
                        Enter User Limit
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Subscription Duration</Form.Label>

                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="time"
                      dateFormat="yyyy-MM-dd HH:mm"
                      className="form-control"
                    />
                    <Form.Control
                      type="hidden"
                      name="subscriptionDuration"
                      value={formValues.subscriptionDuration}
                      onChange={handleChange}
                    />
                    {errors.subscriptionDuration && (
                      <Form.Text className="text-danger">
                        Enter Duration
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="custom_form mb-4 position-relative ">
              <h6>Company Address</h6>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Address 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="address1"
                      value={address1}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.address1 ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Address 1"
                    />
                    {errors.address1 && (
                      <Form.Text className="text-danger">
                        Enter your address
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="address2"
                      value={address2}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.address2 ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Address 2"
                    />
                    {errors.address2 && (
                      <Form.Text className="text-danger">
                        Enter your address2
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      name="province"
                      value={province}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.province ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter State"
                    />
                    {errors.province && (
                      <Form.Text className="text-danger">
                        Enter your State
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={city}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.city ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter City"
                    />
                    {errors.city && (
                      <Form.Text className="text-danger">
                        Enter your city
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalCode"
                      value={postalCode}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.postalCode ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Postal Code"
                    />
                    {errors.postalCode && (
                      <Form.Text className="text-danger">
                        Enter your postal code
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      value={country}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.country ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Country"
                    />
                    {errors.country && (
                      <Form.Text className="text-danger">
                        Enter your country
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              variant="outline-danger"
              className="px-5"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="success" type="submit" className="px-5">
              {formValues.id ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </form>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </Modal>
      {/* Add Company Modal End*/}
    </Container>
  );
}
