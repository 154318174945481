// import axios from "axios";
import axiosInstance from "../axiosInstance";
// import { baseUrl } from "../helpers";

// Post data for Create Users by admin
export const createCompanyUser = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/UserManagement/CreateCompanyUser",
      formdata
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

// Get data for Get Users
export const GetAllCompanyUSers = async () => {
  try {
    const response = await axiosInstance.get(
      "/UserManagement/GetAllCompanyUsers"
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get All GetAllCompanyUsers");
  }
};

export const GetAllCompanyUsersByAdminId = async (id) => {
  try {
    const response = await axiosInstance.get(
      "/UserManagement/GetAllCompanyUsersByAdminId?id=" + id
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get All GetAllCompanyUsers");
  }
};

// Get data for Get Users
export const GetCompanyUserById = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/UserManagement/GetCompanyUserById?id=${id}`
    );
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get CompanyUser by id");
  }
};
// update users
export const updateCompanyUser = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/UserManagement/UpdateCompanyUser",
      formdata
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------updateCompanyUser");
  }
};

//deleteCompanyUser for Delete API
export const deleteCompanyUser = async (id) => {
  try {
    const response = await axiosInstance.post(
      `/UserManagement/DeleteCompanyUser?id=${id}`,
      {}
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error deleting deleteCompanyUser:",
      error.response ? error.response.data : error.message
    );
  }
};

// Active and Inactive user by company or Admin
export const updateStatusofUser = async (id, status) => {
  try {
    const response = await axiosInstance.post(
      `/UserManagement/UpdateStatusofCompanyUser?id=${id}&status=${status}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error updating status:", error);
    return null;
  }
};

export const getProfile = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/UserManagement/GetUserProfile?userid=${id}`
    );

    return response.data;
  } catch (error) {
    console.log(
      error.response ? error.response.data : error.message,
      "--------------------------Get Profile"
    );
  }
};
export const updateProfile = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/UserManagement/UpdateProfile",
      formdata
    );
    console.log(response.data, " Update profile");
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Create Users by Admin");
  }
};
// export const updateProfile = async (formdata) => {
//   const token = localStorage.getItem("AUTH_ACCESS_TOKEN");
//   try {
//     const response = await axios.post(
//       baseUrl + "/UserManagement/UpdateProfile",
//       formdata,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     );
//     console.log(response.data, " Update profile");
//     return response.data;
//   } catch (error) {
//     console.log(error, "--------------------------Create Users by Admin");
//   }
// };
