import axiosInstance from "../axiosInstance";
export const uploadExcelFiles = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      `/ExportImportExcel/UploadExcelFiles`,
      formdata
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Excel File List
export const getExcelFileList = async () => {
  try {
    const response = await axiosInstance.get(
      "/ExportImportExcel/GetExcelFiles"
    );
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get Excel File List");
  }
};
export const getDataByExcelFileId = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/ExportImportExcel/GetFileById?id=${id}`
    );
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get Data By Excel File ID");
  }
};
export const ExportImportExcelStatus = async (id, status) => {
  try {
    const response = await axiosInstance.post(
      `/ExportImportExcel/IsEnableorDisable?id=${id}&status=${status}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error updating status:", error);
    return null;
  }
};

export const deleteExcelData = async (id) => {
  try {
    const response = await axiosInstance.post(
      `/ExportImportExcel/IsDeleted?id=${id}`,
      {}
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error deleting Excel:",
      error.response ? error.response.data : error.message
    );
  }
};
