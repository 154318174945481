import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import {
  GetjobConfiguration,
  GetJobconfigurationByComapnyID,
} from "../service/jobconfigApi.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  increment,
  setJobId,
  setSearchItemInput,
} from "../redux/feature/jobSlice.jsx";
import { setAddedCartLength } from "../redux/feature/usersDataSIice.jsx";
import { toast } from "react-toastify";
import { CreateJobAPI } from "../service/AddCartJobs.js";

import { GetCartsByUsersIdAPI } from "../service/AddCartJobs.js";
import { UpdateJobAPI } from "../service/AddCartJobs.js";

export default function CreateJob() {
  const initialValues = {
    jobscofiguration: "",
    jobscofigurationName: "",
    customername: "",
    email: "",
    phone: "",
    address: "",
    jobName: "",
    jobDescription: "",
    commission: "",
    financefee: "",
    crews: "",
    productName: "",
    productPrice: "",
    equipment: "",
    parts: "",
    permits: "",
    misc: "",
    price: "",
    quantity: "",
    totalPrice: "",
    overheadHours: "",

    laborHighViewmodelhighEmployeeCount: "",
    laborHighViewmodelhighJobHours: "",

    laborMidViewmodelemployeeNeeded: "",
    laborMidViewmodelemployeeNeededmonthlyHours: "",

    laborLowViewModelemployeeNeeded: "",
    laborLowViewModelmonthlyHours: "",

    subContractorViewmodeldaysNeeded: "",
    subContractorViewmodelratePerday: "",

    overheadViewModeldaysNeeded: "",
    overheadViewModelratePerday: "",
  };

  const [jobConfigName, setJobConfigName] = useState("");
  const [jobConfigs, setJobConfigs] = useState(null);
  const [editJobId, setEditJobId] = useState("");
  const [editUserJobId, setEditUserJobId] = useState("");
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const navigate = useNavigate();
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [tooltipValue, setTooltipValue] = useState(0);
  const progress3 = (value1 / 25) * 100;
  const [tooltipVisible2, setTooltipVisible2] = useState(true);
  const [tooltipValue2, setTooltipValue2] = useState(0);
  const [itemDtails, setItemDtails] = useState({
    id: "",
    productName: "",
    productPrice: "",
    typeofSupply: "",
    quantity: "",
    userId: "",
  });
  const { id } = useParams();
  console.log(id, "restData");

  const isEditMode = !!id;

  const [product, setProduct] = useState([]);
  console.log(setProduct);
  const productDetails = useMemo(() => {
    return product.map((item) => ({
      id: item.id,
      name: item.title,
      productPrice: item?.price?.value,
    }));
  }, [product]);

  useEffect(() => {
    if (productDetails.length > 0) {
      const firstProduct = productDetails[0];
      setItemDtails((prevDetails) => ({
        ...prevDetails,
        productName: firstProduct.name,
        productPrice: firstProduct.productPrice,
      }));
    }
  }, [productDetails]);

  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { name, value } = e.target;
    const selectedJobConfig = jobConfigs.find(
      (job) => job.id === parseInt(value)
    );
    // if (value.length > 10) {
    //   toast.error("Input exceeds maximum length of 10 digits.");
    //   throw new Error("Input exceeds maximum length of 10 digits.");
    // }
    setFormValues({
      ...formValues,
      [name]: value,
    });

    if (selectedJobConfig) {
      setJobConfigName(selectedJobConfig.jobConfigName);
    }

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  // const getConfiguration = async () => {
  //   const response = await GetjobConfiguration();
  //   setJobConfigs(response?.data);
  // };
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const userRole = userInfo?.userRole;
  const { companyId } = useSelector((state) => state?.jobSlice);

  let comapany_ID = companyId?.adminID;

  const getConfiguration = async () => {
    let response;
    if (userRole === "CompanyAdmin") {
      response = await GetjobConfiguration();
    } else {
      response = await GetJobconfigurationByComapnyID(comapany_ID);
    }
    setJobConfigs(response?.data);
  };

  useEffect(() => {
    getConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { state } = useLocation();
  console.log("datadata", state);

  // const { editUserDeatils } = useSelector((state) => state.jobSlice);

  const validateForm = () => {
    const newErrors = {};

    // if (!formValues.productName) {
    //   newErrors.productName = "Enter Product Name";
    // }
    if (!formValues.jobscofiguration) {
      newErrors.jobscofiguration = "Select Job Configuration";
    }
    if (!formValues.jobName) {
      newErrors.jobName = "Enter your Job Name";
    }
    // if (!formValues.jobDescription) {
    //   newErrors.jobDescription = "Enter your job description";
    // }
    // if (!formValues.customername) {
    //   newErrors.customername = "Enter your customer name";
    // }
    if (!formValues.jobscofiguration) {
      newErrors.jobsConfiguration = "Enter your job configuration";
    }
    if (!formValues.equipment) {
      newErrors.equipment = "Enter your equipment";
    }
    if (!formValues.parts) {
      newErrors.parts = "Enter your parts";
    }
    if (!formValues.permits) {
      newErrors.permits = "Enter your permits";
    }
    if (!formValues.misc) {
      newErrors.misc = "Enter your miscellaneous items";
    }
    if (!formValues.crews) {
      newErrors.crews = "Enter the number of crews";
    }
    if (!formValues.price) {
      newErrors.price = "Enter the price";
    }
    // if (!formValues.quantity) {
    //   newErrors.quantity = "Enter the quantity";
    // }
    if (isNaN(formValues.price) || formValues.price <= 0) {
      newErrors.price = "Price should be a valid number greater than zero";
    }
    if (!formValues.overheadHours) {
      newErrors.overheadHours = "Enter the overhead hours";
    }
    // if (!formValues.email) {
    //   newErrors.email = "Enter your email address";
    // }
    // if (!formValues.phone) {
    //   newErrors.phone = "Enter your phone number";
    // }
    // if (!formValues.address) {
    //   newErrors.address = "Enter your address";
    // }
    if (!formValues.laborHighViewmodelhighEmployeeCount) {
      newErrors.laborHighViewmodelhighEmployeeCount =
        "Enter the number of high-level employees";
    }
    if (!formValues.laborHighViewmodelhighJobHours) {
      newErrors.laborHighViewmodelhighJobHours =
        "Enter the number of high-level job hours";
    }
    if (!formValues.laborMidViewmodelemployeeNeeded) {
      newErrors.laborMidViewmodelemployeeNeeded =
        "Enter the number of mid-level employees needed";
    }
    if (!formValues.laborMidViewmodelemployeeNeededmonthlyHours) {
      newErrors.laborMidViewmodelemployeeNeededmonthlyHours =
        "Enter the monthly hours for mid-level employees";
    }
    if (!formValues.laborLowViewModelemployeeNeeded) {
      newErrors.laborLowViewModelemployeeNeeded =
        "Enter the number of low-level employees needed";
    }
    if (!formValues.laborLowViewModelmonthlyHours) {
      newErrors.laborLowViewModelmonthlyHours =
        "Enter the monthly hours for low-level employees";
    }
    if (!formValues.overheadViewModeldaysNeeded) {
      newErrors.overheadViewModeldaysNeeded =
        "Enter the number of days needed for subcontractors";
    }
    if (!formValues.overheadViewModelratePerday) {
      newErrors.overheadViewModelratePerday =
        "Enter the subcontractor rate per day";
    }
    if (!formValues.subContractorViewmodeldaysNeeded) {
      newErrors.subContractorViewmodeldaysNeeded =
        "Enter the number of overhead days needed";
    }
    if (!formValues.subContractorViewmodelratePerday) {
      newErrors.subContractorViewmodelratePerday =
        "Enter the overhead rate per day";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    validateForm();
    if (isEditMode) {
      await handleUpdateJob();
    } else {
      await handleCreateJob(e);
    }
  };

  const handleCreateJob = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const userDetails = {
      jobConfigId: formValues.jobscofiguration,
      jobsConfiguration: jobConfigName,
      customerName: formValues.customername,
      email: formValues.email,
      phone: formValues.phone,
      address: formValues.address,
      jobName: formValues.jobName,
      jobDescription: formValues.jobDescription,
      commission: value1,
      financefee: value2,
      crews: formValues.crews,
      productName: formValues.productName,
      productPrice: formValues.equipment,
      equipment: formValues.equipment,
      parts: formValues.parts,
      permits: formValues.permits,
      misc: formValues.misc,
      price: formValues.price,
      quantity: iscount,
      totalPrice: formValues.price * value,
      overheadHours: formValues.overheadHours,
      userId: customerUsersData,
      laborHighViewmodel: {
        highEmployeeCount: formValues.laborHighViewmodelhighEmployeeCount,
        highJobHours: formValues.laborHighViewmodelhighJobHours,
      },
      laborMidViewmodel: {
        employeeNeeded: formValues.laborMidViewmodelemployeeNeeded,
        monthlyHours: formValues.laborMidViewmodelemployeeNeededmonthlyHours,
      },
      laborLowViewModel: {
        employeeNeeded: formValues.laborLowViewModelemployeeNeeded,
        monthlyHours: formValues.laborLowViewModelmonthlyHours,
      },
      subContractorViewmodel: {
        daysNeeded: formValues.subContractorViewmodeldaysNeeded,
        ratePerday: formValues.subContractorViewmodelratePerday,
      },
      overheadViewModel: {
        daysNeeded: formValues.overheadViewModeldaysNeeded,
        ratePerday: formValues.overheadViewModelratePerday,
      },
    };

    try {
      const response = await CreateJobAPI(userDetails);

      toast.success(response?.message);
      if (response.status) {
        navigate(-1);
      }
    } catch (error) {
      console.log("Error while submit the results", error);
    }
  };

  const CustomClose = () => {
    navigate(-1);
    setFormValues(initialValues);
  };

  const handleChange1 = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const newValue = e.target.value;
    setValue1(newValue);
    setTooltipValue(tooltipValue);
  };

  const handleChange2 = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const newValue2 = e.target.value;
    setValue2(newValue2);
    setTooltipValue2(tooltipValue2);
  };

  const progress2 = (value2 / 25) * 100;

  // const handleUpdateProduct = async (e, Id, newQuantity) => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   const productDetails = {
  //     id: Id,
  //     productName: itemDtails.productName ? itemDtails.productName : "string",
  //     productPrice: itemDtails.productPrice,
  //     typeofSupply: selectCategory,
  //     quantity: newQuantity,
  //     userId: itemDtails.userId,
  //   };

  //   try {
  //     const response = await UpdateProductByIdAPI(productDetails);
  //     console.log("UpdateProductByIdAPI", response);
  //     if (response.status) {
  //       handleGetCardByUserId();
  //     }
  //   } catch (error) {
  //     console.log("error while updating the product", error);
  //   }
  // };

  const handleIncrementItems = (Id) => {
    // handleUpdateProduct(Id, itemDtails.quantity + 1);
  };

  const handleDecrementItems = (e) => {
    console.log("handleDecrementItems");
    // if (itemDtails.quantity > 1) {
    //   handleUpdateProduct(e, itemDtails.quantity - 1);
    // }
  };

  const [cartData, setCartData] = useState([]);
  const dispatch = useDispatch();
  const searchItemInput = useSelector(
    (state) => state.jobSlice.searchItemInput
  );

  useEffect(() => {
    const hadleSearch = setTimeout(() => {
      if (searchItemInput.trim() !== "") {
        navigate(`/search?query=${searchItemInput}`);
      }
    }, 5000);

    return () => clearTimeout(hadleSearch);
  }, [searchItemInput, navigate]);

  const iscount = useSelector((state) => state.auth.value);

  const addedCartLength = useSelector((state) => state.auth?.addedCartLength);

  const customerUsersData = useSelector(
    (state) => state.auth.customerUsersData
  );

  const { value } = useSelector((state) => state.jobSlice);

  const handleGetCardByUserId = async () => {
    try {
      const response = await GetCartsByUsersIdAPI(customerUsersData);

      dispatch(setAddedCartLength(response?.data?.cartData?.length));
      setCartData(response?.data?.cartData || []);
    } catch (error) {
      console.error("Error fetching cart data", error);
    }
  };

  useEffect(() => {
    handleGetCardByUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerUsersData]);

  const { jobId } = useSelector((state) => state.jobSlice);

  useEffect(() => {
    if (isEditMode && jobId) {
      setFormValues({
        jobscofiguration: jobId.jobConfigId || "",
        jobscofigurationName: jobId.jobsConfiguration || "",
        customername: jobId.customerName || "",
        email: jobId.email || "",
        phone: jobId.phone || "",
        address: jobId.address || "",
        jobName: jobId.jobName || "",
        jobDescription: jobId.jobDescription || "",
        commission: jobId.commission || "",
        financefee: jobId.financefee || "",
        crews: jobId.crewRunning || "",
        productName: jobId.productName || "",
        productPrice: jobId.productPrice || "",
        equipment: jobId.equipment?.totalSuplly || "",
        parts: jobId.parts?.totalSuplly || "",
        permits: jobId.permits?.totalSuplly || "",
        misc: jobId.misc?.totalSuplly || "",
        price: jobId.price || "",
        quantity: jobId.quantity || "",
        totalPrice: jobId.totalPrice || "",
        overheadHours: jobId.overheadHaurs?.totalLabor || "",

        laborHighViewmodelhighEmployeeCount:
          jobId.laborHigh?.employNeeded || "",
        laborHighViewmodelhighJobHours: jobId.laborHigh?.jobHours || "",

        laborMidViewmodelemployeeNeeded: jobId.laborMid?.employNeeded || "",
        laborMidViewmodelemployeeNeededmonthlyHours:
          jobId.laborMid?.jobHours || "",

        laborLowViewModelemployeeNeeded: jobId.laborLow?.employNeeded || "",
        laborLowViewModelmonthlyHours: jobId.laborLow?.jobHours || "",

        subContractorViewmodeldaysNeeded: jobId.subContractor?.daysNeeded || "",
        subContractorViewmodelratePerday: jobId.subContractor?.ratePerday || "",

        overheadViewModeldaysNeeded: jobId.overhead?.daysNeeded || "",
        overheadViewModelratePerday: jobId.overhead?.ratePerday || "",
      });
      setEditJobId(jobId?.id);
      setEditUserJobId(jobId?.userId);
    }
  }, [isEditMode, jobId]);

  let grandTotal =
    (Number(formValues.equipment) || 0) +
    (Number(formValues.parts) || 0) +
    (Number(formValues.misc) || 0) +
    (Number(formValues.permits) || 0);

  const handleUpdateJob = async (data) => {
    const userDetails = {
      jobConfigId: formValues.jobscofiguration,
      jobsConfiguration: formValues.jobscofigurationName,
      customerName: formValues.customername,
      email: formValues.email,
      phone: formValues.phone,
      address: formValues.address,
      jobName: formValues.jobName,
      jobDescription: formValues.jobDescription,
      commission: value1,
      financefee: value2,
      crews: formValues.crews,
      productName: itemDtails.productName || "string",
      productPrice: grandTotal,
      equipment: formValues.equipment,
      parts: formValues.parts,
      permits: formValues.permits,
      misc: formValues.misc,
      price: formValues.price,
      quantity: iscount,
      totalPrice: formValues.price * value,
      overheadHours: formValues.overheadHours,
      userId: editUserJobId,
      laborHighViewmodel: {
        highEmployeeCount: formValues.laborHighViewmodelhighEmployeeCount,
        highJobHours: formValues.laborHighViewmodelhighJobHours,
      },
      laborMidViewmodel: {
        employeeNeeded: formValues.laborMidViewmodelemployeeNeeded,
        monthlyHours: formValues.laborMidViewmodelemployeeNeededmonthlyHours,
      },
      laborLowViewModel: {
        employeeNeeded: formValues.laborLowViewModelemployeeNeeded,
        monthlyHours: formValues.laborLowViewModelmonthlyHours,
      },
      subContractorViewmodel: {
        daysNeeded: formValues.subContractorViewmodeldaysNeeded,
        ratePerday: formValues.subContractorViewmodelratePerday,
      },
      overheadViewModel: {
        daysNeeded: formValues.overheadViewModeldaysNeeded,
        ratePerday: formValues.overheadViewModelratePerday,
      },
      id: editJobId,
    };

    try {
      const response = await UpdateJobAPI(userDetails);

      if (response.status) {
        dispatch(setJobId(""));
        // dispatch(setEditUserDeatils(false));
        toast.success("Job updated successfully!");
        navigate(-1);
      }
    } catch (error) {
      console.error("Error while updating job", error);
      toast.error("Failed to update job.");
    }
  };

  return (
    <Container fluid className="p-0 my-3 createJobSection">
      <form onSubmit={(e) => handleSubmit(e)}>
        <Row className="mb-3">
          <Col md={12}>
            <h5 className="mb-3">General info</h5>
            <Card className="border-0 p-3 rounded">
              <Row>
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Jobs Configuration</Form.Label>

                    <select
                      name="jobscofiguration"
                      value={formValues.jobscofiguration}
                      onChange={handleChange}
                      className={`px-3 d-block custom-select ${
                        errors.jobscofiguration ? "border-danger mb-0 " : ""
                      }`}
                    >
                      {" "}
                      {jobConfigs?.map((job) => (
                        <option value={job.id} key={job.id}>
                          {job.jobConfigName}
                        </option>
                      ))}
                    </select>
                    {errors.jobscofiguration && (
                      <Form.Text className="text-danger">
                        Enter your jobscofiguration
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="customername"
                      value={formValues.customername}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.customername ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Customer Name"
                    />
                    {errors.customername && (
                      <Form.Text className="text-danger">
                        Enter your customername
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.email ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Email"
                    />
                    {errors.email && (
                      <Form.Text className="text-danger">
                        Enter your email
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="number"
                      name="phone"
                      value={formValues.phone}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.phone ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Phone"
                    />
                    {errors.email && (
                      <Form.Text className="text-danger">
                        Enter your phone
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={formValues.address}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.address ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Address"
                    />
                    {errors.address && (
                      <Form.Text className="text-danger">
                        Enter your address
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Job Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="jobName"
                      value={formValues.jobName}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.jobName ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Job Name"
                    />
                    {errors.jobName && (
                      <Form.Text className="text-danger">
                        Enter your name
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="jobDescription"
                      value={formValues.jobDescription}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.jobDescription ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Description"
                    />
                    {errors.description && (
                      <Form.Text className="text-danger">
                        Enter your description
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h5 className="mb-3  ">Crews </h5>
            <Card className="border-0 p-3 rounded">
              <Row>
                <Col md={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Crews and Fees</Form.Label>
                    <Form.Control
                      type="number"
                      name="crews"
                      value={formValues.crews}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.crews ? "border-danger mb-0" : ""
                      }`}
                      placeholder="132141"
                    />
                    {errors.crews && (
                      <Form.Text className="text-danger">
                        Enter your number crew running
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <h5 className="mb-3">Commission</h5>
            <Card className="border-0 p-3 rounded">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Label>Commission Fee</Form.Label>
                <div className="range-input-container mt-4">
                  <div className="slider-container">
                    <input
                      type="range"
                      id="range"
                      min="0"
                      max="25"
                      value={value1}
                      onChange={handleChange1}
                      onMouseEnter={() => setTooltipVisible(true)}
                      onMouseLeave={() => setTooltipVisible(true)}
                      style={{
                        background: `linear-gradient(to right, #25D366 ${progress3}%, #ccc ${progress3}%)`,
                      }}
                    />
                    {tooltipVisible && (
                      <div>
                        <div
                          className="tooltip"
                          style={{
                            left: `${progress3}%`,
                            transform: "translateX(-50%)",
                          }}
                        >
                          {value1}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="range-labels d-flex justify-content-between">
                    <span>0</span>
                    <span>25</span>
                  </div>
                </div>
              </Form.Group>
            </Card>
          </Col>{" "}
          <Col md={4}>
            <h5 className="mb-3">Finance</h5>
            <Card className="border-0 p-3 rounded">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Label>Finance Fee</Form.Label>
                <div className="range-input-container mt-4">
                  <div className="slider-container">
                    <input
                      type="range"
                      id="range"
                      min="0"
                      max="25"
                      value={value2}
                      onChange={handleChange2}
                      onMouseEnter={() => setTooltipVisible2(true)}
                      onMouseLeave={() => setTooltipVisible2(true)}
                      style={{
                        background: `linear-gradient(to right, #25D366 ${progress2}%, #ccc ${progress2}%)`,
                      }}
                    />
                    {/* <div className="value text-center">{value2}%</div> */}
                    {tooltipVisible2 && ( // Show tooltip while hovering
                      <div>
                        <div
                          className="tooltip-item"
                          style={{
                            left: `${progress2}%`,
                            transform: "translateX(-50%)",
                          }}
                        >
                          {value2}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="range-labels d-flex justify-content-between">
                    <span>0</span>
                    <span>25</span>
                  </div>
                </div>
              </Form.Group>
            </Card>
          </Col>
        </Row>

        {/* Employee Cost and Sub Contractor Cost */}
        <Row className="mb-3 mt-3">
          <Col md={6}>
            <h5 className="mb-3">Employee Cost</h5>
            <Card className="border-0 p-3 rounded">
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Labor High</Form.Label>
                    <Form.Control
                      type="number"
                      name="laborHighViewmodelhighEmployeeCount"
                      value={formValues.laborHighViewmodelhighEmployeeCount}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.laborHighViewmodelhighEmployeeCount
                          ? "border-danger mb-0"
                          : ""
                      }`}
                      placeholder="Employees needed"
                    />
                    {errors.laborHighViewmodelhighEmployeeCount && (
                      <Form.Text className="text-danger">
                        Enter your labor high
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="number"
                      name="laborHighViewmodelhighJobHours"
                      value={formValues.laborHighViewmodelhighJobHours}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.laborHighViewmodelhighJobHours
                          ? "border-danger mb-0"
                          : ""
                      }`}
                      placeholder="Job Hours"
                    />
                    {errors.laborHighViewmodelhighJobHours && (
                      <Form.Text className="text-danger">
                        Enter your job hours
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Labor Mid </Form.Label>
                    <Form.Control
                      type="number"
                      name="laborMidViewmodelemployeeNeeded"
                      value={formValues.laborMidViewmodelemployeeNeeded}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.laborMidViewmodelemployeeNeeded
                          ? "border-danger mb-0"
                          : ""
                      }`}
                      placeholder="Employees needed"
                    />
                    {errors.laborMidViewmodelemployeeNeeded && (
                      <Form.Text className="text-danger">
                        Enter your labor mid
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="number"
                      name="laborMidViewmodelemployeeNeededmonthlyHours"
                      value={
                        formValues.laborMidViewmodelemployeeNeededmonthlyHours
                      }
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.laborMidViewmodelemployeeNeededmonthlyHours
                          ? "border-danger mb-0"
                          : ""
                      }`}
                      placeholder="Job Hours"
                    />
                    {errors.laborMidViewmodelemployeeNeededmonthlyHours && (
                      <Form.Text className="text-danger">
                        Enter your number people
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Labor Low</Form.Label>
                    <Form.Control
                      type="number"
                      name="laborLowViewModelemployeeNeeded"
                      value={formValues.laborLowViewModelemployeeNeeded}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.laborLowViewModelemployeeNeeded
                          ? "border-danger mb-0"
                          : ""
                      }`}
                      placeholder="Employees needed"
                    />
                    {errors.laborLowViewModelemployeeNeeded && (
                      <Form.Text className="text-danger">
                        Enter your number laborlow
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="number"
                      name="laborLowViewModelmonthlyHours"
                      value={formValues.laborLowViewModelmonthlyHours}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.laborLowViewModelmonthlyHours
                          ? "border-danger mb-0"
                          : ""
                      }`}
                      placeholder="Job Hours"
                    />
                    {errors.laborLowViewModelmonthlyHours && (
                      <Form.Text className="text-danger">
                        Enter your number number peoples
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Overhead/Hours</Form.Label>
                    <Form.Control
                      type="number"
                      name="overheadHours"
                      value={formValues.overheadHours}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.overheadHours ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Overhead/Hours"
                    />
                    {errors.overheadHours && (
                      <Form.Text className="text-danger">
                        Enter your number overhead hours
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={12}>
                <h5 className="mb-3 text-white">.</h5>
                <Card className="border-0 p-3 rounded">
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Overhead/Sub Contractor</Form.Label>
                        <Form.Control
                          type="number"
                          name="overheadViewModeldaysNeeded"
                          value={formValues.overheadViewModeldaysNeeded}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.overheadViewModeldaysNeeded
                              ? "border-danger mb-0"
                              : ""
                          }`}
                          placeholder="Days needed"
                        />
                        {errors.overheadViewModeldaysNeeded && (
                          <Form.Text className="text-danger">
                            Enter your number overhead sub
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label></Form.Label>
                        <div className="input-group common_doller_class">
                          <span className="input-group-text">$</span>

                          <Form.Control
                            type="text"
                            name="overheadViewModelratePerday"
                            value={formValues.overheadViewModelratePerday}
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.overheadViewModelratePerday
                                ? "border-danger mb-0"
                                : ""
                            }`}
                            placeholder="Rate/day"
                          />
                        </div>
                        {errors.overheadViewModelratePerday && (
                          <Form.Text className="text-danger">
                            Enter your number rate days
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Sub Contractor Cost</Form.Label>
                        <Form.Control
                          type="number"
                          name="subContractorViewmodeldaysNeeded"
                          value={formValues.subContractorViewmodeldaysNeeded}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.subContractorViewmodeldaysNeeded
                              ? "border-danger mb-0"
                              : ""
                          }`}
                          placeholder="Days needed"
                        />
                        {errors.subContractorViewmodeldaysNeeded && (
                          <Form.Text className="text-danger">
                            Enter your number sub cost
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label></Form.Label>
                        <div className="input-group common_doller_class">
                          <span className="input-group-text">$</span>
                          <Form.Control
                            type="text"
                            name="subContractorViewmodelratePerday"
                            value={formValues.subContractorViewmodelratePerday}
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.subContractorViewmodelratePerday
                                ? "border-danger mb-0"
                                : ""
                            }`}
                            placeholder="Rate/day"
                          />
                        </div>
                        {errors.subContractorViewmodelratePerday && (
                          <Form.Text className="text-danger">
                            Enter your number rate day
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>{" "}
        </Row>
        {/* Supply Cost */}

        <Row className="mb-3">
          <Col md={12}>
            <h5 className="mb-3">Supply Cost</h5>
            {cartData.length > 0 ? (
              <>
                {cartData.map((item) => (
                  <Card className="border-0 p-3 rounded" key={item.id}>
                    <div className="row custom_fields remove_class">
                      <div className="col-sm-12">
                        <div className="row justify-content-end">
                          <div className="col-sm-auto pe-0">
                            {" "}
                            <div className="position-relative search_field_container">
                              <input
                                type="text"
                                className="form-control me-2 mb-0"
                                // value={query}
                                // onChange={handleInputChange}
                                value={searchItemInput}
                                onChange={(e) =>
                                  dispatch(setSearchItemInput(e.target.value))
                                }
                                placeholder="Search for products..."
                              />

                              <button className="position-absolute btn ">
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                              </button>
                            </div>
                            {/* {loadin && <p>Loading...</p>}
                            <ul>
                              {Array.isArray(products) &&
                                products.map((product) => (
                                  <li key={product.id}>{product.name}</li>
                                ))}
                            </ul> */}
                          </div>
                          {/* <div className="col-sm-5 ps-3">
              {" "}
              <input type="file" className="form-control mb-0" />
            </div> */}
                          <div className="col-sm-auto  d-flex align-items-center">
                            <div className="add_Cart_sec position-relative">
                              <div className="notification-count">
                                {addedCartLength || 0}
                              </div>
                              <button
                                className="border-0 add_to_cart rounded "
                                // onClick={handleShow}

                                onClick={() => navigate("/cartListData")}
                              >
                                <FontAwesomeIcon icon={faCartShopping} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col md={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Product Title</Form.Label>

                          <Form.Control
                            type="text"
                            name="productName"
                            value={formValues.productName}
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.productName ? "border-danger mb-0" : ""
                            }`}
                            placeholder="Product Title"
                          />
                          {errors.productName && (
                            <Form.Text className="text-danger">
                              Product Name
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>

                      <Col md={3}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Equipment</Form.Label>
                          <Form.Control
                            type="text"
                            name="equipment"
                            value={
                              (item.typeofSupply === "Equipment"
                                ? item.productPrice
                                : "") || formValues.equipment
                            }
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.equipment ? "border-danger mb-0" : ""
                            }`}
                            placeholder="Equipment "
                            maxLength="10"
                          />

                          {errors.equipment && (
                            <Form.Text className="text-danger">
                              Enter your equipment
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Parts</Form.Label>

                          <Form.Control
                            type="text"
                            name="parts"
                            value={
                              (item.typeofSupply === "Parts"
                                ? item.productPrice
                                : "") || formValues.parts
                            }
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.parts ? "border-danger mb-0" : ""
                            }`}
                            placeholder="Parts"
                            maxLength={10}
                          />

                          {errors.parts && (
                            <Form.Text className="text-danger">
                              Enter your parts
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Permits</Form.Label>

                          <Form.Control
                            type="text"
                            name="permits"
                            value={
                              (item.typeofSupply === "Permits"
                                ? item.productPrice
                                : "") || formValues.permits
                            }
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.permits ? "border-danger mb-0" : ""
                            }`}
                            placeholder="Permits"
                            maxLength={10}
                          />

                          {errors.permits && (
                            <Form.Text className="text-danger">
                              Enter your permits
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Misc</Form.Label>

                          <Form.Control
                            type="text"
                            name="misc"
                            value={
                              (item.typeofSupply === "Misc"
                                ? item.productPrice
                                : "") || formValues.misc
                            }
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.misc ? "border-danger mb-0" : ""
                            }`}
                            placeholder="Misc"
                            maxLength={10}
                          />

                          {errors.misc && (
                            <Form.Text className="text-danger">
                              Enter your misc
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Form.Label>Quantity</Form.Label>
                        <div className="quantity">
                          <button
                            onClick={(e) => handleDecrementItems(item.id)}
                          >
                            -
                          </button>
                          <span className="mx-2">{iscount || 0}</span>
                          <button
                            onClick={(e) => handleIncrementItems(item.id)}
                          >
                            +
                          </button>
                        </div>
                      </Col>

                      <Col md={3}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Price</Form.Label>

                          <Form.Control
                            type="text"
                            name="price"
                            value={formValues.price}
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.price ? "border-danger mb-0" : ""
                            }`}
                            placeholder="Price"
                            maxLength={10}
                          />

                          {errors.price && (
                            <Form.Text className="text-danger">
                              Enter your Price
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Total Price </Form.Label>

                          <Form.Control
                            type="text"
                            name="totalPrice"
                            value={formValues.totalPrice}
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.totalPrice ? "border-danger mb-0" : ""
                            }`}
                            placeholder="Total Price"
                          />

                          {errors.totalPrice && (
                            <Form.Text className="text-danger">
                              Enter your Total Price
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Grand Total</Form.Label>

                          <Form.Control
                            type="text"
                            name="price"
                            value={grandTotal}
                            onChange={handleChange}
                            className={`px-3 ${
                              errors.price ? "border-danger mb-0" : ""
                            }`}
                            placeholder="Grand Total"
                          />

                          {errors.price && (
                            <Form.Text className="text-danger">
                              Grand Total
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </>
            ) : (
              <Card className="border-0 p-3 rounded">
                <div className="row custom_fields remove_class">
                  <div className="col-sm-12">
                    <div className="row justify-content-end">
                      <div className="col-sm-auto pe-0">
                        {" "}
                        <div className="position-relative search_field_container">
                          <input
                            type="text"
                            className="form-control me-2 mb-0"
                            // value={query}
                            // onChange={handleInputChange}
                            value={searchItemInput}
                            onChange={(e) =>
                              dispatch(setSearchItemInput(e.target.value))
                            }
                            placeholder="Search for products..."
                          />

                          <button className="position-absolute btn ">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </button>
                        </div>
                        {/* {loading && <p>Loading...</p>} */}
                        {/* <ul>
                          {Array.isArray(products) &&
                            products.map((product) => (
                              <li key={product.id}>{product.name}</li>
                            ))}
                        </ul> */}
                      </div>
                      {/* <div className="col-sm-5 ps-3">
            {" "}
            <input type="file" className="form-control mb-0" />
          </div> */}
                      <div className="col-sm-auto  d-flex align-items-center">
                        <div className="add_Cart_sec position-relative">
                          <div className="notification-count">
                            {addedCartLength || 0}
                          </div>
                          <button
                            className="border-0 add_to_cart rounded "
                            // onClick={handleShow}
                            onClick={() => navigate("/cartListData")}
                          >
                            <FontAwesomeIcon icon={faCartShopping} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Product Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="productName"
                        value={formValues.productName}
                        onChange={handleChange}
                        className={`px-3 ${
                          errors.productName ? "border-danger mb-0" : ""
                        }`}
                        placeholder="Product Title"
                      />
                      {errors.productName && (
                        <Form.Text className="text-danger">
                          Product Name
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Equipment</Form.Label>
                      <div className="input-group common_doller_class">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          name="equipment"
                          value={formValues.equipment}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.equipment ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Equipment"
                          maxLength={10}
                        />
                      </div>
                      {errors.equipment && (
                        <Form.Text className="text-danger">
                          Enter your equipment
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Parts</Form.Label>
                      <div className="input-group common_doller_class">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          name="parts"
                          value={formValues.parts}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.parts ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Parts"
                          maxLength={10}
                        />
                      </div>
                      {errors.parts && (
                        <Form.Text className="text-danger">
                          Enter your parts
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Permits</Form.Label>
                      <div className="input-group common_doller_class">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          name="permits"
                          value={formValues.permits}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.permits ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Permits"
                          maxLength={10}
                        />
                      </div>
                      {errors.permits && (
                        <Form.Text className="text-danger">
                          Enter your permits
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Misc</Form.Label>
                      <div className="input-group common_doller_class">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          name="misc"
                          value={formValues.misc}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.misc ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Misc"
                          maxLength={10}
                        />
                      </div>
                      {errors.misc && (
                        <Form.Text className="text-danger">
                          Enter your misc
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={1}>
                    <Form.Label>Quantity</Form.Label>
                    <div className="quantity">
                      <button
                        type="button"
                        onClick={() => dispatch(decrement())}
                      >
                        -
                      </button>
                      <span className="mx-2">{value || "1"}</span>
                      <button
                        type="button"
                        onClick={() => dispatch(increment())}
                      >
                        +
                      </button>
                    </div>
                  </Col>

                  <Col md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Price</Form.Label>
                      <div className="input-group common_doller_class">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          name="price"
                          value={formValues.price}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.price ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Price"
                          maxLength={10}
                        />
                      </div>
                      {errors.price && (
                        <Form.Text className="text-danger">
                          Enter your Price
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Total Price</Form.Label>
                      <div className="input-group common_doller_class">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          name="totalPrice"
                          value={formValues.price * value}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.totalPrice ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Total Price"
                        />
                      </div>
                      {errors.totalPrice && (
                        <Form.Text className="text-danger">
                          Enter your Total Price
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Grand Total</Form.Label>
                      <div className="input-group common_doller_class">
                        <span className="input-group-text">$</span>
                        <Form.Control
                          type="text"
                          name="price"
                          value={grandTotal + Number(formValues.price * value)}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.price ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Grand Total"
                        />
                      </div>
                      {errors.price && (
                        <Form.Text className="text-danger">
                          Grand Total
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          {" "}
          <Button type="submit" variant="success" className="me-2">
            {isEditMode ? "Update Job" : "Submit Job"}
          </Button>
          <Button variant="danger" onClick={CustomClose}>
            Cancel
          </Button>
        </div>
      </form>
    </Container>
  );
}
