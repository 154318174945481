// import { configureStore } from "@reduxjs/toolkit";
// import jobSlice from "../feature/jobSlice";
// import profileReducer from "../feature/profileSlice";

// const store = configureStore({
//   reducer: {
//     jobSlice,
//     profile: profileReducer,
//   },
// });

// export default store;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import jobSliceReducer from "../feature/jobSlice";
import profileReducer from "../feature/profileSlice"; // Corrected import path
import storage from 'redux-persist/lib/storage';
import authReducer from '../feature/usersDataSIice'
import { persistStore, persistReducer } from "redux-persist"; // Corrected import paths

const rootReducer = combineReducers({
  jobSlice: jobSliceReducer,
  profile: profileReducer,
  auth: authReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], 
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,  
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

// Persistor
export const persistor = persistStore(store);


