import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddToCartAPI, SearchProductsList } from "../service/AddCartJobs";
import {
  setOnChangeFunc,
  setOpenTypeofSupply,
  setSearchItemInput,
  setSelectCategory,
} from "../redux/feature/jobSlice";

import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { Breadcrumb, Container } from "react-bootstrap";
// import { increment } from '../redux/feature/usersDataSIice';
import { toast } from "react-toastify";
import { SelectPopup } from "../components/SelectPopup";
// import StarOutlineIcon from '@mui/icons-material/StarOutline';

export const SearchItemsPage = () => {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemDtails, setItemDtails] = useState({
    productName: "",
    productPrice: "",
    typeofSupply: "",
  });

  const queryParameters = new URLSearchParams(window.location.search);
  const query = queryParameters.get("query");
  const onChangeFunc = useSelector((state) => state.jobSlice.onChangeFunc);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchItemInput = useSelector(
    (state) => state.jobSlice.searchItemInput
  );
  console.log("searchItemInput", searchItemInput);

  const handleSearch = async () => {
    if (searchItemInput.length || query.length > 2) {
      try {
        const response = await SearchProductsList(query);
        console.log(response.related_searches, "Itemsdatata");
        setProduct(response.related_searches);
        dispatch(setSearchItemInput(""));
        setLoading(false);
        productDetails();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setProduct([]);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItemInput]);

  const productDetails = useMemo(() => {
    return product.map((item) => ({
      id: item.id,
      name: item.query,
      productPrice: item?.price?.value,
    }));
  }, [product]);

  useEffect(() => {
    if (productDetails.length > 0) {
      console.log("productDetails", productDetails);

      const firstProduct = productDetails[0];
      setItemDtails((prevDetails) => ({
        ...prevDetails,
        productName: firstProduct.name,
        productPrice: firstProduct.productPrice,
      }));
    }
  }, [productDetails]);
  console.log("itemDtailssss", itemDtails.productName);

  const iscount = useSelector((state) => state.auth.value);
  console.log("iscount", iscount);

  const customerUsersData = useSelector(
    (state) => state.auth.customerUsersData
  );
  console.log("customerUsersData", customerUsersData);

  // const [openPopup, setOpenPopup] = useState(false)
  const openTypeofSupply = useSelector(
    (state) => state.jobSlice.openTypeofSupply
  );
  const selectCategory = useSelector((state) => state.jobSlice.selectCategory);
  console.log("selectCategory", selectCategory);

  const handleAddtoCart = async () => {
    dispatch(setOpenTypeofSupply(true));
    console.log("product", product);

    const cartDetails = {
      productName: itemDtails.productName,
      productPrice: itemDtails.productPrice,
      typeofSupply: selectCategory,
      quantity: iscount,
      userId: customerUsersData,
    };
    if (selectCategory) {
      try {
        const response = await AddToCartAPI(cartDetails);
        console.log("cartDetails", response);
        toast.success(response?.message);
        dispatch(setSelectCategory(""));
        dispatch(setOpenTypeofSupply(false));
        dispatch(setOnChangeFunc(false));
        setTimeout(() => {
          navigate("/CreateJob");
        }, 5000);
      } catch (error) {
        console.error("Error adding to cart:", error);
        toast.error("Something went wrong, please try again.");
      }
    }
  };
  console.log("onChangeFunc", onChangeFunc);
  useEffect(() => {
    if (onChangeFunc) {
      handleAddtoCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCategory, onChangeFunc]);

  return (
    <Container fluid className="p-0 my-3 ">
      <div className="d-flex justify-content-between align-items-center">
        <div className="main_title mb-2"> Product List</div>
        <button onClick={() => window.history.back()}>Go Back</button>

        <Breadcrumb>
          <Breadcrumb.Item href="/AdditionalRevenue"></Breadcrumb.Item>
        </Breadcrumb>
        {/* <Button className="btn custom-btn mb-3 border-0" onClick={handleShow}>
          <FontAwesomeIcon icon={faPlus} /> Add Revenue
        </Button> */}
      </div>
      <div>
        {loading ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          <>
            <div className=" mb-4">
              <div className="row g-3">
                {product?.length > 0 ? (
                  <>
                    {product?.map((item, index) => (
                      <div className="col-md-3" key={index}>
                        <div
                          className="card  h-100"
                          style={{
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          }}
                        >
                          <div className="img-box d-flex justify-content-center align-items-center ">
                            <img
                              src={item.image}
                              alt={item.title}
                              style={{
                                width: "230px",
                                height: "230px",
                                display: "block",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="pb-3 pt-3">
                              {item.title.slice(0, 50) + "..."}
                            </h5>
                            {/* <div className="rating-box p-2 border-1 bg-success">

                                                        <h5 className='pb-3 pt-3'>{item.rating}</h5>
                                                    </div> */}

                            <div className="d-flex justify-content-between align-items-center">
                              {/* <h5 className='pb-3 pt-3'>{item.recent_sales}</h5> */}
                              <h5 className="pb-3 pt-3">{item?.price?.raw}</h5>
                              <button
                                className="btn btn-success"
                                onClick={handleAddtoCart}
                              >
                                ADD TO CART
                              </button>
                            </div>
                            <div>
                              {/* <Button variant='contained' color='error'>BUT NOW</Button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p>No products found</p>
                )}
              </div>
              {openTypeofSupply ? <SelectPopup /> : ""}
            </div>
          </>
        )}
      </div>
    </Container>
  );
};
