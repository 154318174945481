import Form from "react-bootstrap/Form";
import logo from "../assets/image/logo.png";
import lock from "../assets/image/lock.png";
import eye from "../assets/image/eye.png";
import { useState } from "react";
import eye_off from "../assets/image/eye_off.png";
import Alert from "react-bootstrap/Alert";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { signupApi } from "../service/authApi";
import { toast } from "react-toastify"; 
import SampleSlider from "../widget/Slider/SampleSlider";
import ReactLoading from "react-loading";

function CreatePassword() {
  const navigate = useNavigate();
  const [showpassword, setshowPassword] = useState(false);
  const [showpassword1, setshowPassword1] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const [touched, setTouched] = useState({
    password: false,
    confirmPassword: false,
  });
  const formData = location?.state?.formdata;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTouched({ password: true, confirmPassword: true });
    setLoader(true);
    if (password !== confirmPassword) {
      setError("Passwords do not match");
    } else if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters, contain one digit, and one special character."
      );
    } else {
      try {
        setError(null);
        const { phone, ...rest } = formData;
        const data = {
          password: password,
          phonenumber: phone,
          ...rest,
        };

        const response = await signupApi(data);

        if (response?.status) {
          toast.success("User is Created successfully!", {
            autoClose: 5000,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
        // console.log("Password created successfully!");
      } catch (error) {
        console.log(error, "error");
      } finally {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    }
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const isPasswordValid =
    password && confirmPassword && password === confirmPassword && !error;

  return (
    <>
      <div className="container-fluid bg-white custom-parent">
        <div className="row custom-row">
          <div className="col-lg-6 ">
            <SampleSlider />
          </div>
          <div className="col-lg-6">
            <div className="login-form">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <div className="form_text">
                <div className="heading d-flex align-item-center">
                  <span></span>
                  <h1 className="main-heading">Create Password</h1>
                </div>
                <p className="sub-heading mb-4">
                  Your new password must be different from previous used
                  password.
                </p>
                {error && (
                  <Alert variant="danger" className="mb-4">
                    {error}
                  </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <div className="form-field">
                    <Form.Group className="mb-3" controlId="password">
                      <div className="position-relative">
                        <img
                          src={lock}
                          alt=""
                          className="left_icon position-absolute"
                        />
                        <Form.Control
                          type={showpassword ? "text" : "password"}
                          placeholder="Password"
                          className={`px-5 ${
                            touched.password &&
                            (error
                              ? "border-danger"
                              : isPasswordValid
                              ? "" 
                              : "")
                          }`}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onFocus={() =>
                            setTouched((prev) => ({
                              ...prev,
                              password: true,
                            }))
                          }
                        />
                        <img
                          src={showpassword ? eye : eye_off}
                          alt=""
                          className="position-absolute right_icon"
                          onClick={() => setshowPassword(!showpassword)}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="confirmPassword">
                      <div className="position-relative">
                        <img
                          src={lock}
                          alt=""
                          className="left_icon position-absolute"
                        />
                        <Form.Control
                          type={showpassword1 ? "text" : "password"}
                          placeholder="Confirm Password"
                          className={`px-5 ${
                            touched.confirmPassword &&
                            (error ||
                              (password &&
                                confirmPassword &&
                                password !== confirmPassword))
                              ? "border-danger"
                              : isPasswordValid
                              ? ""
                              : ""
                          }`}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          onFocus={() =>
                            setTouched((prev) => ({
                              ...prev,
                              confirmPassword: true,
                            }))
                          }
                        />
                        <img
                          src={showpassword1 ? eye : eye_off}
                          alt=""
                          className="position-absolute right_icon"
                          onClick={() => setshowPassword1(!showpassword1)}
                        />
                      </div>
                    </Form.Group>
                    <div className="d-grid gap-2 mb-4">
                      <Button
                        type="submit"
                        className="btn btn-success common-btn"
                        size="lg"
                      >
                        Create
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CreatePassword;
