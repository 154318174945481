

import axiosInstance from "../axiosInstance";

// first signup api
export const sendOtpApi = async (formdata) => {
  try {
    const response = await axiosInstance.post("/Account/SendOtp", formdata);
    return response.data;
  } catch (error) {
    // console.log(error.response && error.response.data.message);
  }
};

export const validateOtpApi = async (formdata) => {
  try {
    const response = await axiosInstance.post("/Account/ValidateOtp", formdata);
    return response.data;
  } catch (error) {
    return { status: false };
  }
};
// third api create password
export const signupApi = async (formdata) => {
  try {
    const response = await axiosInstance.post("/Account/SignUp", formdata);
    return response.data;
  } catch (error) {
    return error;
  }
};

// fourth api login
export const loginApi = async (formdata) => {
  try {
    const response = await axiosInstance.post("/Account/Login", formdata);

    await localStorage.clear();
    await localStorage.setItem("AUTH_ACCESS_TOKEN", response.data.data.token);
    await localStorage.setItem(
      "AUTH_REFRESH_TOKEN",
      response.data.data.refreshToken
    );
    await localStorage.setItem(
      "AUTH_USER",
      JSON.stringify(response.data.data.userInfo)
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// fifth api forgot password

export const forgotPassApi = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/Account/ForgotPassword",
      formdata
    );

    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

// sixth api reset password

export const resetPassword = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/Account/ResetPassword",
      formdata
    );

    return response.data;
  } catch (error) {
    // console.log(error, "error====");
  }
};

// Get All Users
export const checkEmailExistsApi = async (email) => {
  try {
    const response = await axiosInstance.get("/Account/GetAllUsers", {
      email,
    });
    return response.data;
  } catch (error) {}
};

export const logoutApi = async (token, refreshToken) => {
  try {
    const response = await axiosInstance.post(
      `/Account/Logout?token=${token}`,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async (token, refreshToken) => {
  try {
    const response = await axiosInstance.post(
      `/Account/RefreshToken?accessToken=${token}&refreshToken=${refreshToken}`,
      {}
    );
    console.log(response.data, "return____");
    return response.data;
  } catch (error) {
    console.error("Logout error:", error);
    throw error;
  }
};



export const changePassword = async (
  email,
  oldPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const response = await axiosInstance.post("/Account/ChangePassword", {
      email,
      oldPassword,
      newPassword,
      confirmPassword,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
