import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowLeft,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/image/logo.png";
import { Link, useNavigate } from "react-router-dom";
import settings from "../assets/image/settings.png";
import dashboard from "../assets/image/dashboard.png";
import arevenue from "../assets/image/arevenue.png";
import umanage from "../assets/image/umanage.png";
import xls from "../assets/image/xls.png";
// import customer from "../assets/image/umanage.png";
import { GetjobConfiguration } from "../service/jobconfigApi";
import { useSelector } from "react-redux";
import logoutIcon from "../assets/image/logoutIcon.png";
import { toast } from "react-toastify";
import { logoutApi } from "../service/authApi";
import Swal from "sweetalert2";
const Sidebar = ({ toggleHandler }) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [activeSubMenu1, setActiveSubMenu1] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [jobConfigs, setJobConfigs] = useState(null);
  const jobUpdateFlag = useSelector((state) => state.jobSlice.jobUpdateFlag);
  const navigate = useNavigate();

  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const userRole = userInfo?.userRole;
  const subMenuRef = useRef(null);

  const toggleSubMenu = (menu) => {
    if (activeSubMenu === menu) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(menu);
      setActiveSubMenu1(null);
    }
  };

  const handleSubMenuClick = (job) => {
    setActiveSubMenu1(job.id);
    setActiveMenu("settings");
  };

  const getConfiguration = async () => {
    const response = await GetjobConfiguration();
    setJobConfigs(response?.data);
  };

  useEffect(() => {
    if (activeMenu === "JobConfiguration") {
      getConfiguration();
    }
  }, [jobUpdateFlag, activeMenu]);

  useEffect(() => {
    if (activeMenu !== "settings") {
      setActiveSubMenu(null);
    }
  }, [activeMenu]);

  const handleLogout = async (e) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Logout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    });

    if (result.isConfirmed) {
      const token = localStorage.getItem("AUTH_ACCESS_TOKEN");

      if (!token) {
        toast.error("No token found. Please log in again.");
        navigate("/login");
        return;
      }

      try {
        const resp = await logoutApi(token);
        if (resp.status) {
          setTimeout(() => {
            toast.success(resp.message);
          }, 0);
          localStorage.clear();

          navigate("/login");
        } else {
          setTimeout(() => {
            toast.success("User logged out successfully");
          }, 0);

          localStorage.clear();
          window.location.href = "/Login";
        }
      } catch (error) {
        console.error(
          "Logout error:",
          error.response ? error.response.data : error.message
        );

        localStorage.clear();
        window.location.href = "/Login";
      }
    }
  };

  return (
    <div className="side-bar">
      <div className="brandArea text-center ">
        <span onClick={toggleHandler} className="sidebar-toggle">
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>
        <Link to="/Dashboard">
          {" "}
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="menubar">
        <div className="menu">
          {userRole && (
            <>
              <div className="item">
                <Link
                  to="/Dashboard"
                  className={activeMenu === "dashboard" ? "nav-active" : ""}
                  onClick={() => setActiveMenu("dashboard")}
                >
                  <img src={dashboard} alt="" />
                  <span>Dashboard</span>
                </Link>
              </div>

              {userRole === "CompanyAdmin" && (
                <div className="item">
                  <Link
                    to="/UserManagement"
                    className={
                      activeMenu === "userManagement" ? "nav-active" : ""
                    }
                    onClick={() => setActiveMenu("userManagement")}
                  >
                    <img src={umanage} alt="" />
                    <span>User Management</span>
                  </Link>
                </div>
              )}
              {userRole === "CompanyUser" && (
                <div className="item">
                  <Link
                    to="/AllJobs"
                    className={activeMenu === "AllJobs" ? "nav-active" : ""}
                    onClick={() => setActiveMenu("AllJobs")}
                  >
                    <img src={arevenue} alt="" />
                    <span>Create Jobs</span>
                  </Link>
                </div>
              )}
              {userRole === "CompanyAdmin" && (
                <div className="item">
                  <div className="position-relative">
                    <div className="start_class"></div>

                    <Link
                      to="/jobConfiguration"
                      className={`sub-btn position-relative ${
                        activeMenu === "settings" ? "nav-active" : ""
                      }`}
                      onClick={() => {
                        setActiveMenu("JobConfiguration");
                        toggleSubMenu("settings");
                      }}
                    >
                      <img src={settings} alt="" />
                      <span>Job Configuration</span>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className={`dropdown ${
                          activeSubMenu === "settings" ? "rotate" : ""
                        }`}
                      />
                    </Link>
                  </div>
                  {activeSubMenu === "settings" && (
                    <div className="sub-menu" ref={subMenuRef}>
                      {jobConfigs?.map((job) => (
                        <Link
                          key={job.id}
                          className={`sub-item ${
                            activeSubMenu1 === job.id ? "active" : ""
                          }`}
                          to={`/jobConfiguration/${job.id}`}
                          state={job}
                          handleSubMenuClick
                          onClick={() => handleSubMenuClick(job)}
                        >
                          <FontAwesomeIcon icon={faPencil} className="me-2" />
                          <span>{job.jobConfigName}</span>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {(userRole === "CompanyAdmin" || userRole === "CompanyUser") && (
                <div className="item">
                  <Link
                    to="/AdditionalRevenue"
                    className={
                      activeMenu === "additionalRevenue" ? "nav-active" : ""
                    }
                    onClick={() => setActiveMenu("additionalRevenue")}
                  >
                    <img src={arevenue} alt="" />
                    <span>Additional Revenue</span>
                  </Link>
                </div>
              )}

              {/* <div className="item">
                <Link
                  to="/Customer"
                  className={activeMenu === "customer" ? "nav-active" : ""}
                  onClick={() => setActiveMenu("customer")}
                >
                  <img src={customer} alt="" />
                  <span>Customer</span>
                </Link>
              </div> */}

              {userRole === "SuperAdmin" && (
                <div className="item">
                  <Link
                    to="/UploadExcel"
                    className={activeMenu === "UploadExcel" ? "nav-active" : ""}
                    onClick={() => setActiveMenu("UploadExcel")}
                  >
                    <img src={xls} alt="" />
                    <span>Manage Excel</span>
                  </Link>
                </div>
              )}
              {/* {userRole === "CompanyAdmin" && (
                <div className="item">
                  <Link
                    to="/AllJobs"
                    className={`sub-btn ${
                      activeMenu === "/AllJobs" ? "nav-active" : ""
                    }`}
                    onClick={() => {
                      setActiveMenu("/AllJobs");
                      toggleSubMenu("/AllJobs");
                    }}
                  >
                    <img src={settings} alt="" />
                    <span>User List</span>
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className={`dropdown ${
                        activeSubMenu === "/AllJobs" ? "rotate" : ""
                      }`}
                    />
                  </Link>
                  {activeSubMenu === "/AllJobs" && (
                    <div className="sub-menu" ref={subMenuRef}>
                      {addUser?.map((user) => (
                        <Link
                          key={state?.id}
                          to={`/AllJobs/${state?.id}`}
                          state={user}
                          onClick={() => handleSubMenuClick(user)}
                        >
                          <FontAwesomeIcon icon={faPencil} className="me-2" />
                          <span>
                            {user?.firstName} {user?.lastName}
                          </span>
                        </Link>
                      ))}
                      {addUser?.length === 0 && (
                        <div className="no-users">
                          <span>No users found</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )} */}

              <div className="d-flex justify-content-center logoutbtnArea p-3 position-absolute bottom-0 w-100">
                <Link to="" className="btn btn-dark" onClick={handleLogout}>
                  {" "}
                  <img src={logoutIcon} alt="" /> <span>Logout</span>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
