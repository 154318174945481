import {
  faEye,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Datatable from "../widget/table/Datatable";

export default function Customer() {
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    jobs: "",
    address: "",
  };
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);

  const { name, phone, email, address, jobs } = formValues;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();
    // handleClose1()
    setFormValues(initialValues);
  };
  const validateForm = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = "Enter your name";
    }

    if (!phone) {
      newErrors.phone = "Enter your phone number";
    } else if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = "Phone number must be exactly 10 digits";
    }
    if (!email) {
      newErrors.email = "Enter your email";
    }
    if (!jobs) {
      newErrors.jobs = "Enter your jobs";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const columns = [
    {
      name: "Customer Name",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Jobs",
      selector: (row) => row.jobs,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="actionBtngroup">
          <Button variant="" className=" btn custom-btn px-2 py-1 w-auto">
            <FontAwesomeIcon icon={faEye} className="me-1" />
            View Customer
          </Button>
          <Button variant="" onClick={() => handleEdit(row.id)}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button variant="" onClick={() => handleDelete(row.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      title: "Jane Cooper",
      email: "xyz@info.com",
      address: "Lorem Ipsum",
      jobs: "Software",
      phone: "+123456789",
    },
    {
      id: 2,
      title: "Jane Cooper",
      email: "xyz@info.com",
      address: "Lorem Ipsum",
      jobs: "Software",
      phone: "+123456789",
    },
  ];



  const [Modalshow1, setModalshow1] = useState(false);
  const [Modalshow2, setModalshow2] = useState(false);

  const handleClose1 = () => setModalshow1(false);
  const handleClose2 = () => setModalshow2(false);
  const handleShow = () => setModalshow1(true);
  const handleEdit = (id) => {
    setModalshow2(true);
    console.log("Edit clicked for ID:", id);
  };

  const handleDelete = (id) => {
    console.log("Delete clicked for ID:", id);
  
  };

  return (
    <Container fluid className="p-0 my-3  ">
      <div className="d-flex justify-content-between align-items-center ">
        {" "}
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/AdditionalRevenue">
              Customer
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Button className="btn custom-btn mb-3 border-0" onClick={handleShow}>
            <FontAwesomeIcon icon={faPlus} /> Add Customer
          </Button>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <div className="border-0 p-3 rounded card">
            <div className="title-container">
              <p>Customer List</p>
            </div>
            <Datatable columns={columns} data={data} />
          </div>
        </Col>
      </Row>
    
      {/* Add Customer Modal Start*/}
      <Modal show={Modalshow1} onHide={handleClose1} centered size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Add Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="custom_form mb-4 position-relative">
              <h6>Customer Info</h6>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.name ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Customer name"
                    />
                    {errors.name && (
                      <Form.Text className="text-danger">
                        Enter your name
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.email ? "border-danger mb-0" : ""
                      }`}
                      placeholder="xyz@info.com"
                    />
                    {errors.email && (
                      <Form.Text className="text-danger">
                        Enter your email
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={address}
                      onChange={handleChange}
                      // className={`px-3 ${
                      //   errors.address ? "border-danger mb-0" : ""
                      // }`}
                      className=" px-3"
                      placeholder="Address"
                    />
                    {/* {errors.address && (
                      <Form.Text className="text-danger">
                        Enter your address
                      </Form.Text>
                    )} */}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Jobs</Form.Label>
                    <Form.Control
                      type="text"
                      name="jobs"
                      value={jobs}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.jobs ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Jobs"
                    />
                    {errors.jobs && (
                      <Form.Text className="text-danger">
                        Enter your jobs
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  {/* <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={phone}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.phone ? "border-danger mb-0" : ""
                      }`}
                      placeholder="+123456789"
                    />
                    {errors.phone && (
                      <Form.Text className="text-danger">
                        Enter your phone
                      </Form.Text>
                    )}
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={phone}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.phone ? "border-danger mb-0" : ""
                      }`}
                      placeholder="+1 (751) 505-4112"
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.phone && (
                      <Form.Text className="text-danger">
                        {errors.phone === "Enter your number"
                          ? "Enter your number"
                          : "Phone number must be exactly 10 digits"}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              variant="outline-danger"
              className="px-5"
              onClick={handleClose1}
            >
              Cancel
            </Button>
            <Button variant="success" type="submit" className="px-5">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* Add Revenue Modal End*/}

      {/* Add Revenue Modal End*/}

      {/* ========================================================================= */}
      {/* Edit Revenue Modal start */}
      <Modal show={Modalshow2} onHide={handleClose2} centered size="lg">
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="custom_form mb-4 position-relative">
            <h6>Customer Info</h6>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Customer name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="xyz@info.com" />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" placeholder="Address" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Jobs</Form.Label>
                  <Form.Control type="text" placeholder="Jobs" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="text" placeholder="+123456789" />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            variant="outline-danger"
            className="px-5"
            onClick={handleClose2}
          >
            Cancel
          </Button>
          <Button variant="success" className="px-5" onClick={handleClose2}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit Revenue Modal End */}
    </Container>
  );
}
