// =============================Dev====================================
// export const baseUrl = "https://excelyourrevenue-cont-dev.chetu.com/api";
// export const baseUrlForFile = "https://excelyourrevenue-cont-dev.chetu.com";

// =======================QA =====================================
// export const baseUrl = "https://excelyourrevenue-cont-api.chetu.com/api";
// export const baseUrlForFile = "https://excelyourrevenue-cont-api.chetu.com";

//=========================UAT ===============================
export const baseUrl = "https://uatapi.excelyourrevenue.com/api";
export const baseUrlForFile = "https://uatapi.excelyourrevenue.com";

export const currentUser = () => {
  const user = localStorage.getItem("AUTH_USER");
  return user ? JSON.parse(user) : null;
};

export const currentoken = () => {
  return localStorage.getItem("AUTH_ACCESS_TOKEN");
};
export const refreshToken = () => {
  return localStorage.getItem("AUTH_REFRESH_TOKEN");
};

export const fullName = () => {
  const user = currentUser();
  return `${user?.firstName} ${user?.lastName}`;
};

export const isAuthenticated = () => {
  return localStorage.getItem("AUTH_ACCESS_TOKEN") ? true : false;
};
