import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import PageNotFound from "./pages/PageNotFound";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import AllJobs from "./pages/AllJobs";
import UserManagement from "./pages/UserManagement";
import JobDetails from "./pages/JobDetails";
import AdditionalRevenue from "./pages/AdditionalRevenue";
import UserProfile from "./pages/UserProfile";
import Otp from "./pages/Otp";
import CreatePassword from "./pages/CreatePassword";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Customer from "./pages/Customer";
import PrivateRoute from "./pages/PrivateRoute";
import JobConfiguration from "./pages/JobConfiguration";
import Company from "./pages/SuperDashboard";
import CreateJob from "./pages/CreateJob";
import VideoList from "./pages/VideoList";
import JobList from "./pages/JobList";
import Products from "./pages/Products";
import UploadExcel from "./pages/UploadExcel";
import { SearchItemsPage } from "./pages/SearchItemsPage";
import { AddedCartList } from "./pages/AddedCartList";
export default function Router() {
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const userRole = userInfo?.userRole;

  const RoleBasedRoute = ({ element, allowedRoles }) => {
    return allowedRoles.includes(userRole) ? (
      element
    ) : (
      <Navigate to="/Dashboard" />
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/Otp" element={<Otp />} />
        <Route path="/CreatePassword" element={<CreatePassword />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/" element={<Layout />}>
          <Route path="" element={<PrivateRoute />}>
            <Route
              path="/Dashboard"
              element={
                <RoleBasedRoute
                  element={<Dashboard />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin", "CompanyUser"]}
                />
              }
            />

            <Route
              path="/AllJobs"
              element={
                <RoleBasedRoute
                  element={<AllJobs />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin", "CompanyUser"]}
                />
              }
            />
            <Route
              path="/JobList"
              element={
                <RoleBasedRoute
                  element={<JobList />}
                  allowedRoles={["CompanyAdmin", "CompanyUser"]}
                />
              }
            />
            <Route
              path="/JobDetails"
              element={
                <RoleBasedRoute
                  element={<JobDetails />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin", "CompanyUser"]}
                />
              }
            />
            <Route
              path="/UserManagement"
              element={
                <RoleBasedRoute
                  element={<UserManagement />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin"]}
                />
              }
            />
            <Route
              path="/Products"
              element={
                <RoleBasedRoute
                  element={<Products />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin"]}
                />
              }
            />
            <Route
              path="/UploadExcel"
              element={
                <RoleBasedRoute
                  element={<UploadExcel />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin"]}
                />
              }
            />
            <Route
              path="/AdditionalRevenue"
              element={
                <RoleBasedRoute
                  element={<AdditionalRevenue />}
                  allowedRoles={["CompanyAdmin", "CompanyUser"]}
                />
              }
            />
            <Route
              path="/VideoList"
              element={
                <RoleBasedRoute
                  element={<VideoList />}
                  allowedRoles={["SuperAdmin"]}
                />
              }
            />
            <Route
              path="/CreateJob"
              element={
                <RoleBasedRoute
                  element={<CreateJob />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin", "CompanyUser"]}
                />
              }
            />

            <Route
              path="/EditJob/:id"
              element={
                <RoleBasedRoute
                  element={<CreateJob />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin", "CompanyUser"]}
                />
              }
            />
                        <Route path="/search" element={<SearchItemsPage/>}/>
                        <Route path="/cartListData" element={<AddedCartList/>}/>
            <Route
              path="/JobConfiguration"
              element={
                <RoleBasedRoute
                  element={<JobConfiguration />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin"]}
                />
              }
            />
            <Route
              path="/JobConfiguration/:id"
              element={
                <RoleBasedRoute
                  element={<JobConfiguration />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin"]}
                />
              }
            />
            <Route
              path="/UserProfile"
              element={
                <RoleBasedRoute
                  element={<UserProfile />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin", "CompanyUser"]}
                />
              }
            />
            <Route
              path="/Customer"
              element={
                <RoleBasedRoute
                  element={<Customer />}
                  allowedRoles={["SuperAdmin", "CompanyAdmin", "CompanyUser"]}
                />
              }
            />
            <Route
              path="/SuperDashboard"
              element={
                <RoleBasedRoute
                  element={<Company />}
                  allowedRoles={["SuperAdmin"]}
                />
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
