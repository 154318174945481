import { faPencil, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  additionalRevenue,
  updateAdditionalRevenue,
  GetAlladditionalRevenue,
  DeleteadditionalRevenue,
} from "../service/additionalRevenueApi";
import { toast } from "react-toastify";
import DataTable from "../widget/table/Datatable";
import Swal from "sweetalert2";

import ReactLoading from "react-loading";
export default function AdditionalRevenue() {
  const initialValues = {
    id: null,
    jobName: "",
    description: "",
    amount: "",
    date: moment(),
  };

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const { jobName, description, amount, date } = formValues;
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => {
    setModalshow(false);
    setFormValues(initialValues);
    setErrors({});
  };
  const handleShow = () => setModalshow(true);
  const [Modalshow, setModalshow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };
  const handleDateChange = (date1) => {
    const date = new Date(date1);
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", date1);
      return;
    }

    const pad = (num) => num.toString().padStart(2, "0");
    const formattedDate = `${date?.getFullYear()}-${pad(
      date.getMonth() + 1
    )}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(
      date.getMinutes()
    )}:${pad(date.getSeconds())}.${date.getMilliseconds()}`;

    setStartDate(formattedDate);
    setFormValues({
      ...formValues,
      date: formattedDate,
    });

    // if (errors.date) {
    //   setErrors((prevErrors) => ({ ...prevErrors, date: false }));
    // }
  };

  // const handleDateChange = (date1) => {
  //   const date = new Date(date1);
  //   const pad = (num) => num.toString().padStart(2, "0");
  //   const formattedDate = `${date?.getFullYear()}-${pad(
  //     date?.getMonth() + 1
  //   )}-${pad(date?.getDate())}T${pad(date?.getHours())}:${pad(
  //     date?.getMinutes()
  //   )}:${pad(date?.getSeconds())}.${date?.getMilliseconds()}`;
  //   console.log(formattedDate, "datedaT");
  //   setStartDate(formattedDate);
  //   setFormValues({
  //     ...formValues,
  //     date: formattedDate,
  //   });
  //   if (errors.date) {
  //     setErrors((prevErrors) => ({ ...prevErrors, date: false }));
  //   }
  // };

  const validateForm = () => {
    const newErrors = {};
    if (!jobName) {
      newErrors.jobName = "Enter your name";
    }
    if (!description) {
      newErrors.description = "Enter your description";
    }
    if (!amount) {
      newErrors.amount = "Enter your revenue amount";
    }

    if (!date) {
      newErrors.date = "Enter your date";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Add Revenue
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      setLoader(true);
      try {
        let response = null;

        const obj = {
          jobName: formValues.jobName,
          description: formValues.description,
          amount: formValues.amount,
        };
        if (formValues.date) {
          const newDate = moment(formValues.date);
          obj.date = newDate.format("YYYY-MM-DD");
        }

        if (formValues.id) {
          obj.id = formValues.id;
          response = await updateAdditionalRevenue(obj);
          toast.success("Revenue is Updated successfully!", {
            autoClose: 3000,
          });
        } else {
          response = await additionalRevenue(obj);
          toast.success("Revenue is added successfully!", {
            autoClose: 3000,
          });
        }
        if (response) {
          getAdditionalRevenueData();
          setFormValues(initialValues);
          handleClose();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    }
  };
  // Edit Revenue
  const handleEdit = (data) => {
    setFormValues({
      ...formValues,
      id: data.id,
      jobName: data.jobName,
      description: data.description,
      amount: data.amount,
      date: data.date,
    });
    setModalshow(true);
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.jobName,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Revenue Amount",
      selector: (row) => row.amount,
      cell: (row) => <span className="text_ui">${row.amount}</span>,
      sortable: true,
    },

    {
      name: "Date",
      // selector: (row) => row.date.replace("T", " "),
      selector: (row) => moment(row.date).format("MM/DD/YYYY"),

      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="actionBtngroup">
          <Button className="custom_edit" onClick={() => handleEdit(row)}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            className=" custom_delete"
            onClick={() => handleDelete(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];
  // Get Revenue
  useEffect(() => {
    getAdditionalRevenueData();
  }, []);

  const getAdditionalRevenueData = async () => {
    const result = await GetAlladditionalRevenue();
    setData(result?.data);
  };
  // Delete Revenue
  const handleDelete = async (id) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed) {
        const response = await DeleteadditionalRevenue(id);
        if (response) {
          getAdditionalRevenueData();
          Swal.fire(
            "Deleted!",
            "Revenue has been deleted successfully!",
            "success"
          );
        } else {
          Swal.fire("Error!", "Failed to delete revenue.", "error");
        }
      }
    } catch (error) {
      toast.error("Error submitting form:", error);
    }
  };

  return (
    <Container fluid className="p-0 my-3 additional_revenue">
      <div className="d-flex justify-content-between align-items-center">
        <Breadcrumb>
          <Breadcrumb.Item href="/AdditionalRevenue">
            Additional Revenue
          </Breadcrumb.Item>
        </Breadcrumb>
        <Button className="btn custom-btn mb-3 border-0" onClick={handleShow}>
          <FontAwesomeIcon icon={faPlus} /> Add Revenue
        </Button>
      </div>
      <Row className="mb-3">
        <Col md={12}>
          <div className="border-0 p-3 rounded card addRevenue">
            <div className="title-container">
              <p>Revenue List</p>
            </div>
            <DataTable columns={columns} data={data} filterName="jobName" />
          </div>
        </Col>
      </Row>

      {/* Add and Edit Revenue Modal Start */}
      <Modal show={Modalshow} onHide={handleClose} centered size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>
              {" "}
              {formValues.id ? "Edit Revenue" : "Add Revenue "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="custom_form mb-4 position-relative">
              <h6>Revenue Info</h6>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="jobName"
                      value={jobName}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.jobName ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter name"
                    />
                    {errors.jobName && (
                      <Form.Text className="text-danger">
                        {errors.jobName}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={description}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.description ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter description"
                    />
                    {errors.description && (
                      <Form.Text className="text-danger">
                        {errors.description}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Revenue Amount</Form.Label>
                    <Form.Control
                      type="number"
                      name="amount"
                      value={amount}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.amount ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter amount"
                    />
                    {errors.amount && (
                      <Form.Text className="text-danger">
                        {errors.amount}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="d-block">Date</Form.Label>
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      // showTimeSelect
                      // timeFormat="HH:mm"
                      // timeIntervals={15}
                      // timeCaption="time"
                      // dateFormat="yyyy-MM-dd HH:mm"
                      dateFormat="yyyy-MM-dd "
                      className="form-control "
                    />
                    <Form.Control
                      type="hidden"
                      name="date"
                      value={formValues.date}
                      onChange={handleChange}
                    />
                    {errors.date && (
                      <Form.Text className="text-danger">
                        {errors.date}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              variant="outline-danger"
              className="px-5"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button type="submit" variant="success" className="px-5">
              {formValues.id ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </form>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </Modal>
      {/* Add and Edit Revenue Modal End */}
    </Container>
  );
}
