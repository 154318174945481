import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteProductByIdAPI, GetCartsByUsersIdAPI, UpdateProductByIdAPI } from '../service/AddCartJobs'
import { setAddedCartLength } from '../redux/feature/usersDataSIice'
import { toast } from 'react-toastify'
import { setOpenTypeofSupply } from '../redux/feature/jobSlice'
import { SelectPopup } from '../components/SelectPopup'

export const AddedCartList = () => {

    const [productDetails, setProductDetails] = useState('')
    const [cartData, setCartData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [itemDtails, setItemDtails] = useState({
        id:'',
        productName: '',
        productPrice: '',
        typeofSupply: '',
        quantity: '',
        userId: ''

    });

    const dispatch = useDispatch()

    const selectCategory = useSelector((state) => state.jobSlice.selectCategory);
    console.log('selectCategory', selectCategory)
    const customerUsersData = useSelector((state) => state.auth.customerUsersData)
    console.log("customerUsersData", customerUsersData);
    const onChangeFunc = useSelector((state) => state.jobSlice.onChangeFunc)
    console.log("selectCategory", selectCategory)


    const handleGetCardByUserId = async () => {
        try {
            const response = await GetCartsByUsersIdAPI(customerUsersData);
            console.log("handleGetCardByUserId", response);
            dispatch(setAddedCartLength(response?.data?.cartData?.length))
            setProductDetails(response.data)
            setCartData(response?.data?.cartData || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {

        handleGetCardByUserId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerUsersData])

    const handleDeleteProduct = async (Id) => {
        console.log("handleDeleteProducr", Id)
        const response = await DeleteProductByIdAPI(Id)
        console.log("DeleteProductByIdAPI", response)
        toast.success(response?.message)
        handleGetCardByUserId()
    }

    const saveproductDetails = useMemo(() => {
        return cartData.map((item) => ({
            id:item.id,
            productName: item.productName,
            productPrice: item.productPrice,
            typeofSupply: item.typeofSupply,
            quantity: item.quantity,
            userId: item?.userId

        }));
    }, [cartData]);
    console.log("cartData",cartData)
    useEffect(() => {
        if (saveproductDetails.length > 0) {
            console.log("saveproductDetails", saveproductDetails);

            const firstProduct = saveproductDetails[0];
            setItemDtails((prevDetails) => ({
                ...prevDetails,
                productName: firstProduct.name,
                productPrice: firstProduct.productPrice,
                typeofSupply: firstProduct.typeofSupply,
                quantity: firstProduct.quantity,
                userId: firstProduct?.userId,
                id:firstProduct.id
            }));
        }

    }, [saveproductDetails]);

    console.log("cartData.productName", itemDtails.id)



    const handleUpdateProduct = async (Id, newQuantity) => {
        console.log("newQuantity", Id)
        const productDetails = {
            "id": Id,
            "productName": itemDtails.productName ? itemDtails.productName : 'string',
            "productPrice": itemDtails.productPrice,
            "typeofSupply": selectCategory,
            "quantity": newQuantity,
            "userId": itemDtails.userId
        }
        try {
            const response = await UpdateProductByIdAPI(productDetails)
            dispatch(setOpenTypeofSupply(false))
            console.log("UpdateProductByIdAPI", response)
            if (response.status) {
                handleGetCardByUserId()
            }
        } catch (error) {
            console.log("error while update the product", error)
        }
    }

    const handleDecrementItems = (Id) => {
        if (itemDtails.quantity > 1) {

            handleUpdateProduct(Id, itemDtails.quantity - 1);
        }
    }
    const handleIncrementItems = (Id) => {
        handleUpdateProduct(Id, itemDtails.quantity + 1);
    }
    const openTypeofSupply = useSelector((state) => state.jobSlice.openTypeofSupply)
    console.log("openTypeofSupply", openTypeofSupply)


    const handleTypeofSupplyed = () => {
        dispatch(setOpenTypeofSupply(true))
    }
    useEffect(() => {
        if (onChangeFunc) {
            handleUpdateProduct(itemDtails.id); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectCategory, onChangeFunc]);

    return (
        <>
            <div className="custom_form1 mb-4 position-relative">
                {isLoading ? (
                    <p>Loading...</p>
                ) :
                    cartData.length === 0 ? (
                        <p>No items in the cart.</p>
                    ) : (
                        <div>
                            {cartData.map((item) => (
                                <div key={item.id}>
                                    <Table striped className="table product_sec">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Total Price</th>
                                                <th>typeofSupply</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="custom_img">
                                                    {item.productName}
                                                </td>
                                                <td> ${item.productPrice}</td>
                                                <td>
                                                    {" "}
                                                    <div className="quantity">
                                                        <button onClick={() => handleDecrementItems(item.id)}>-</button>
                                                        {item.quantity}
                                                        {console.log("item.quantity", item.quantity)}
                                                        <button onClick={() => handleIncrementItems(item.id)}>+</button>
                                                    </div>
                                                </td>
                                                <td>{(item.productPrice) * (item.quantity)}</td>
                                                <td>
                                                    <button className="dele_btn" onClick={() => handleDeleteProduct(item.id)}>
                                                        {" "}
                                                        <FontAwesomeIcon icon={faTrash} className="" />
                                                    </button>
                                                </td>
                                                <td>
                                                    <div onClick={() => handleTypeofSupplyed(item.id)}>
                                                        {item.typeofSupply}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            ))}
                        </div>
                    )}
            </div>
            <div className="custom_form1 mb-4 position-relative">
                <Table striped className="summery_sec">
                    <tbody>
                        <tr>
                            <td>
                                {" "}
                                <label for="promo">Code promo</label>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    id="promo"
                                    className="w-100 border-white form-control h-50 mb-0"
                                />
                            </td>
                            <td>
                                <Button className="btn-app">Apply</Button>
                            </td>
                        </tr>
                        <tr>
                            <td>Order Summary</td>
                            <td colSpan={2} className="text-end">
                                $2700
                            </td>
                        </tr>
                        <tr>
                            <td>shipping</td>

                            <td colSpan={2} className="text-end">
                                Free
                            </td>
                        </tr>
                        <tr>
                            <td>Total amount</td>
                            <td colSpan={2} className="text-end">
                                {productDetails?.totalPrice}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {openTypeofSupply ? <SelectPopup /> : ''}
            </div>

        </>
    )
}
