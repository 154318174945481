import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import Datatable from "../widget/table/Datatable";
import { getAllJobs } from "../service/jobs";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faLeftLong } from "@fortawesome/free-solid-svg-icons";

export default function JobList() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const { state } = location;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchJobs = async () => {
    const allJobs = await getAllJobs();
    if (allJobs) {
      const filterJobs = allJobs?.data.filter(
        (job) => job.status === state?.status
      );
      setData(filterJobs);
    }
  };
  useEffect(() => {
    fetchJobs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      name: "Job Name",
      selector: (row) => row.jobName,
      sortable: true,
    },
    {
      name: "Date",
      // selector: (row) => row.createdDate,
      selector: (row) => moment(row.createdDate).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: "Crews",
      selector: (row) => row.crewRunning,
      sortable: true,
    },
    {
      name: "Commission",
      selector: (row) => `${row.commission}%`,
      sortable: true,
    },
    {
      name: "Finance Fee",
      selector: (row) => `${row.financefee}%`,
      sortable: true,
    },
    {
      name: "Breakeven Cost",
      selector: (row) => `$${row.breakEvenJobCost.toLocaleString()}`,
      sortable: true,
    },
    {
      name: "Bid Cost",
      cell: (row) => `$${row.bidCost.toLocaleString()}`,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <Button
          variant={
            row.status === "Pending"
              ? "warning"
              : row.status === "Lost"
              ? "danger"
              : row.status === "Awarded"
              ? "success"
              : "info"
          }
        >
          {row.status}
        </Button>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="actionBtngroup ">
          <Link
            to="/JobDetails"
            state={{
              jobId: row?.id,
              jobName: row?.jobName,
              jobConfigId: row?.jobConfigId,
            }}
            className="btn custom-btn px-1 py-1 w-auto custom-width"
          >
            <FontAwesomeIcon icon={faEye} />
            Job Details
          </Link>
          {/* <Button className="custom_edit">
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button className=" custom_delete">
            <FontAwesomeIcon icon={faTrash} />
          </Button> */}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="mb-2">
        <Link to="/Dashboard" className="text-decoration-none btn  btn-success">
          <FontAwesomeIcon icon={faLeftLong} /> Back
        </Link>
      </div>
      <Card className="border-0 p-3 rounded mb-3">
        <div className="title-container">
          <p>{state?.status} Jobs List</p>
        </div>
        <Datatable columns={columns} data={data} filterName="jobName" />
      </Card>
    </div>
  );
}
