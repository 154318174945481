// import { createSlice } from "@reduxjs/toolkit";

// const jobSlice = createSlice({
//   name: "jobSlice",
//   initialState: {
//     jobUpdateFlag: false,
//   },
//   reducers: {
//     updateJobFlag: (state) => {
//       state.jobUpdateFlag = !state.jobUpdateFlag;
//     },
//   },
// });

// export const { updateJobFlag } = jobSlice.actions;
// export default jobSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const jobSlice = createSlice({
  name: "jobSlice",
  initialState: {
    jobUpdateFlag: false,
    searchItemInput: "",
    selectCategory: "",
    openTypeofSupply: false,
    onChangeFunc: false,
    editUserDeatils: false,
    jobId: "",
    value: 1,
    companyId: "",
  },
  reducers: {
    updateJobFlag: (state) => {
      state.jobUpdateFlag = !state.jobUpdateFlag;
    },
    setSearchItemInput: (state, action) => {
      state.searchItemInput = action.payload;
    },
    setSelectCategory: (state, action) => {
      state.selectCategory = action.payload;
    },
    setOpenTypeofSupply: (state, action) => {
      state.openTypeofSupply = action.payload;
    },
    setOnChangeFunc: (state, action) => {
      state.onChangeFunc = action.payload;
    },
    setEditUserDeatils: (state, action) => {
      state.editUserDeatils = action.payload;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      if (state.value > 1) {
        state.value -= 1;
      }
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
  },
});

export const {
  setCompanyId,
  increment,
  decrement,
  incrementByAmount,
  setJobId,
  setEditUserDeatils,
  setOnChangeFunc,
  setOpenTypeofSupply,
  updateJobFlag,
  setSearchItemInput,
  setSelectCategory,
} = jobSlice.actions;
export default jobSlice.reducer;
