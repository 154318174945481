import axiosInstance from "../axiosInstance";

// Get data for Job Details
export const getAllJobs = async () => {
  try {
    const response = await axiosInstance.get("/Job/GetAllJobs");
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get All Jobs");
  }
};
// update job
export const updateJobsdata = async () => {
  try {
    const response = await axiosInstance.post("/Job/UpdateJob");
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get All Jobs");
  }
};

// Get data for Job Details by user id
export const GetJobByUserId = async (id) => {
  try {
    const response = await axiosInstance.get("/Job/GetJobByUserId?id=" + id);
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get All Jobs");
  }
};
// count pending jobs
export const countPendingJobs = async () => {
  try {
    const response = await axiosInstance.get("/Job/count-pending-jobs");
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Count Pending Jobs");
  }
};
//count lost jobs
export const countLostJobs = async () => {
  try {
    const response = await axiosInstance.get("/Job/count-lost-jobs");
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Count Lost Jobs");
  }
};
//count awarded jobs
export const countAwardedJobs = async () => {
  try {
    const response = await axiosInstance.get("/Job/count-awarded-jobs");
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Count Awarded Jobs");
  }
};
// Awarded,Pending and Lost jobs by company Admin or user
export const updateStatusofUser = async (id, newStatus) => {
  try {
    const response = await axiosInstance.post(
      `/Job/update-status?JobId=${id}&newStatus=${newStatus}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error("Error updating status:", error);
    return null;
  }
};

export const getCustomJobById = async (id) => {
  try {
    const response = await axiosInstance.get(`/Job/GetJobById?id=${id}`);
    return response.data;
  } catch (error) {}
};

export const updateJobAmount = async (id, bidCost) => {
  try {
    const response = await axiosInstance.post(`/Job/UpdateJob`, {
      id,
      bidCost,
    });
    return response.data;
  } catch (error) {}
};
// bid cost amount update
export const addBidCostAmount = async (id, bidCost) => {
  try {
    const response = await axiosInstance.post(
      `/Job/AddBidCostAmount?jobId=${id}&bidCostAmount=${bidCost}`,
      {}
    );
    return response.data;
  } catch (error) {}
};
//update Status
export const jobStatusUpdate = async (id, newStatus) => {
  try {
    const response = await axiosInstance.post(
      `/Job/update-status?JobId=${id}&newStatus=${newStatus}`
    );

    return response.data;
  } catch (error) {}
};
//break even job cost
export const GetBreakEvenCost = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/Job/GetBreakEvenCost?jobconfigId=${id}`
    );
    return response.data;
  } catch (error) {}
};

export const gettopTenJobs = async () => {
  try {
    const response = await axiosInstance.get("/Job/GetTopTenJobs");

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get Top Ten Jobs");
  }
};

export const getJobCost = async (id, message) => {
  try {
    const response = await axiosInstance.get(
      `/Job/GetJobCost?jobconfigid=${id}&status=${message}`
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteJobData = async (id) => {
  try {
    const response = await axiosInstance.post(`/Job/DeleteJob?id=${id}`, {});

    return response.data;
  } catch (error) {
    console.error(
      "Error deleting Excel:",
      error.response ? error.response.data : error.message
    );
  }
};
