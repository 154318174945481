import React, { useEffect, useState } from "react";

export default function Footer() {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);
  return (
    <div className="footer d-flex justify-content-center bg-white rounded-2 p-2 mb-3 ">
      <h6 className="text-success"> &copy; {year} - All Rights Reserved</h6>
    </div>
  );
}
