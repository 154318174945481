import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'auth',
  initialState: {
    customerUsersData:'',
    addedCartLength:[],
  },
  reducers: {

    setCustomerUsersData:(state, actions)=>{
        state.customerUsersData = actions.payload;
    },
    setAddedCartLength:(state, action)=>{
        state.addedCartLength=action.payload
        console.log("state.addedCartLength",state.addedCartLength)
      },
  },
})

export const {setCustomerUsersData,setAddedCartLength} = userSlice.actions

export default userSlice.reducer;


