import axios from "axios";
import { baseUrl } from "./helpers";
const baseURL = baseUrl;

const axiosInstance = axios.create({
  baseURL: baseURL,
});
axiosInstance.interceptors.request.use(
  (config) => {
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("AUTH_ACCESS_TOKEN");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error, "datra");
    if (error.response.status === 401) {
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = localStorage.getItem("AUTH_ACCESS_TOKEN");
        const refreshToken = localStorage.getItem("AUTH_REFRESH_TOKEN");
        const response = await axios.post(
          `${baseURL}/Account/RefreshToken?accessToken=${token}`,
          {},
          {
            Authorization: `Bearer ${refreshToken}`,
          }
        );
        const newToken = response.data.access_token;
        localStorage.setItem("AUTH_ACCESS_TOKEN", newToken);
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${newToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        if (error.response.status === 401) {
          localStorage.clear();
          //alert("Session has timed out. Please log in.");
          window.location.href = "/login";
        } else {
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
